import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ChargerApi from 'app/views/charger/ChargerApi';
import { useThemeContext } from 'app/ThemeProvider';


export default function StickyHeadTable({chargeBoxId}) {
  const [page, setPage] = React.useState(0);
  const [rows,setRows]=React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const api=new ChargerApi();
  const { darkTheme } = useThemeContext();

  React.useEffect(()=>{

    fetchLastMeterValues();

  },[]);

  const fetchLastMeterValues = () => {
      api
        .getLastMeterValues({chargeBoxId:chargeBoxId})
        .then((response) => {
          console.log(response.data)
          setRows(response.data);
        })
        .catch((e) => {
          // return (<p>Data unavailable: API Request failed, check Charger or Network Status.</p>)
        });
  }



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    
    <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: darkTheme? '#333333'  : 'white'}}>
      <TableContainer sx={{ maxHeight:"100vh", backgroundColor: darkTheme? '#333333'  : 'white'}}>
        <Table stickyHeader aria-label="sticky table" sx={{ backgroundColor: darkTheme? '#333333'  : 'white'}}>
          <TableHead sx={{ backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}}>
            <TableRow sx={{ backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}}>
            <TableCell sx={{backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}} style={{fontWeight:'bold' ,paddingLeft:10 }} align="left">Connector ID</TableCell>
            <TableCell sx={{backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}} style={{fontWeight:'bold' ,width:"200px"}} align="left">Measurand</TableCell>
            <TableCell sx={{backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}} style={{fontWeight:'bold' }} align="right">Location</TableCell>
            <TableCell sx={{backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}} style={{fontWeight:'bold' }} align="right">Value</TableCell>
            <TableCell sx={{backgroundColor: darkTheme? '#333333'  : 'white', color: darkTheme?'#f2e34c' : 'black'}} style={{fontWeight:'bold' }} align="center">Unit</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row,index) => (
            <TableRow sx={{color: darkTheme? '#f2e34c' : 'black', backgroundColor: darkTheme? '#333333' : 'white'}} id="abc" key={row.name}>
              <TableCell align="left" sx={{color: darkTheme? '#f2e34c' : 'black', backgroundColor: darkTheme? '#333333' : 'white'}} style={{  paddingLeft:30 }}>{row.connector_id}</TableCell>
              <TableCell align="left" sx={{color: darkTheme? '#f2e34c' : 'black', backgroundColor: darkTheme? '#333333' : 'white'}}>{row.measurand}</TableCell>
              <TableCell align="right" sx={{color: darkTheme? '#f2e34c' : 'black', backgroundColor: darkTheme? '#333333' : 'white'}}>{row.location}</TableCell>
              <TableCell align="right" sx={{color: darkTheme? '#f2e34c' : 'black', backgroundColor: darkTheme? '#333333' : 'white'}}>{row.value}</TableCell>
              <TableCell align="center" sx={{color: darkTheme? '#f2e34c' : 'black', backgroundColor: darkTheme? '#333333' : 'white'}}>{row.unit}</TableCell>
              
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}

