import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import AccessManagementAnalytics from './accessmngmnt/AccessManagementAnalytics';



const AppForm = Loadable(lazy(() => import('./vechilemgmt/AppForm')));
const DepoForm = Loadable(lazy(() => import('./depomgmt/DepoForm')));
const ChargerBayForm = Loadable(lazy(() => import('./chagerbaymgmt/ChargerBayForm')));
const ChargerForm = Loadable(lazy(() => import('./chargermgmt/ChargerForm')));
const VehicelSlotForm = Loadable(lazy(() => import('./vehicletslot/VehicleSlot/VehicelSlotForm')));
const OrgForm = Loadable(lazy(() => import('./orgmgmt/OrgForm')));
const UserForm = Loadable(lazy(() => import('./usrmgmt/UserForm')));
const DepotUserForm = Loadable(lazy(() => import('./depotusrmgmt/DepoUserForm')));
const ChargeProfileForm = Loadable(lazy(() => import('./chargeprofile/ChargeProfileForm')));
const ChargeConnectorForm = Loadable(lazy(() => import('./chargeconnector/ChargeConnectorForm')));
const LoadMgmtProfileForm = Loadable(lazy(() => import('./loadmgmt/ChargeProfileForm')));
const InfraForm = Loadable(lazy(() => import('./infrastructure/InfraForm')));
const EnergyandForm = Loadable(lazy(() => import('../../views/energy/Analytics')));
const EventsForm = Loadable(lazy(() => import('../../views/eventsandalarms/Analytics')));
const ArrivalsAndDepratures = Loadable(lazy(() => import('../../views/arrivalDeparture/Analytics')));
const DesignFeasibility = Loadable(lazy(() => import('./designfsb/DesignFeasibility')));
// const MGLDash = Loadable(lazy(() => import('../../views/mglDashUpdate/mgldash')));

const managementRoutes = [
  {
    path: '/cmsmgmt/depomgmt',
    element: <DepoForm />,
  },
  {
    path: '/cmsmgmt/vechilemgmt',
    element: <AppForm />,
  },
  {
    path: '/cmsmgmt/vechilemgmt',
    element: <AppForm />,
  },
  {
    path: '/cmsmgmt/chagerbaymgmt',
    element: <ChargerBayForm />,
  },

  {
    path: '/cmsmgmt/chargermgmt',
    element: <ChargerForm />,
  },
  {
    path: '/cmsmgmt/vehicletslot',
    element: <VehicelSlotForm />,
  },
  {
    path: '/cmsmgmt/orgmgmt',
    element: <OrgForm />,
  },
  {
    path: '/cmsmgmt/usrmgmt',
    element: <UserForm />,
  },
  {
    path: '/cmsmgmt/depotusrmgmt',
    element: <DepotUserForm />,
  },
  {
    path: '/cmsmgmt/chargeprofile',
    element: <ChargeProfileForm />,
  },
  {
    path: '/cmsmgmt/loadmgmt',
    element: <LoadMgmtProfileForm />,
  },
  {
    path: '/cmsmgmt/chargeconnector',
    element: <ChargeConnectorForm />,
  },
  {
    path: '/cmsmgmt/infra',
    element: <InfraForm />,
  },
  {
    path: '/cmsmgmt/energy',
    element: <EnergyandForm />,
  },{
    path: '/cmsmgmt/evets',
    element: <EventsForm />,
  },{
    path: '/cmsmgmt/arrivals',
    element: <ArrivalsAndDepratures />,
  },{
    path: '/cmsmgmt/arrivals',
    element: <ArrivalsAndDepratures />,
  },
  {
    path: '/cmsmgmt/designfsb',
    element: <DesignFeasibility />,
  },
  {
    path: '/cmsmgmt/access-management',
    element: <AccessManagementAnalytics />,
  },
  
  // {
  //   path: '/cmsmgmt/mgldash',
  //   element: <MGLDash />,
  // },
 
 
  

  
];

export default managementRoutes;
