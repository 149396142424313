// import AssessmentIcon from '@mui/icons-material/Assessment';
// import ChargingStationIcon from '@mui/icons-material/ChargingStation';
// import CorporateFareIcon from '@mui/icons-material/CorporateFare';
// import DatasetIcon from '@mui/icons-material/Dataset';
// import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@material-ui/core';



const superAdminNavigations = [


  {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Dashboard </Typography>,
    path: '/dashboard/default',
    icon: <img src="./../assets/images/menuicons/dashboard.svg" alt="dashboard" style={{ position: 'absolute' }} />,
  }
  // ,
  // {
  //   name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>depoView </Typography>,
  //   path: '/depoView/default',
  //   icon: <img src="./../assets/images/menuicons/vehicles.svg" alt="vehicles" style={{ position: 'absolute' }} />,
  // }
  , {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Vehicles </Typography>,
    path: '/vehicle/default',
    icon: <img src="./../assets/images/menuicons/vehicles.svg" alt="vehicles" style={{ position: 'absolute' }} />,
  }, {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Chargers </Typography>,
    path: '/charger/default',
    icon: <img src="./../assets/images/menuicons/chargers.svg" alt="chargers" style={{ position: 'absolute' }} />,
  }
  // {
  //   name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}  noWrap={true}>Arrival and Departures </Typography>,
  //   path: '/cmsmgmt/arrivals',
  //   icon:    <img src="./../assets/images/menuicons/arrivalsanddepratures.svg" alt="arrivalsanddepratures"  style={{position:'absolute'}} />,
  // },
  //  {
  //   name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}  noWrap={true}>Energy and Power</Typography>,
  //   path: '/cmsmgmt/energy',
  //   icon:    <img src="./../assets/images/menuicons/energyandpower.svg" alt="energyandpower"  style={{position:'absolute'}} />,
  // }
  , {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Events and Alarms </Typography>,
    path: '/cmsmgmt/evets',
    icon: <img src="./../assets/images/menuicons/eventsandalarms.svg" alt="eventsandalarms" style={{ position: 'absolute' }} />,
  }, {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Reports</Typography>,
    path: '/reports/default',
    icon: <img src="./../assets/images/menuicons/reports.svg" alt="reports" style={{ position: 'absolute' }} />,
  },


  // {
  //   name: <Typography noWrap={true} fontSize={14} fontFamily="Arial" style={{marginTop:'8px'}}>System Setup</Typography>,
  //   icon: <img src="./../assets/images/menuicons/systemsetup.svg" alt="systemsetup"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px'}}  fontFamily="Arial" noWrap={true}>Organization and Depot</Typography>,
  //       path: '/cmsmgmt/infra',
  //       icon:    <img src="./../assets/images/menuicons/electricvehicle.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //     },
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px'}}  fontFamily="Arial" noWrap={true}>Vehicles and Chargers</Typography>,
  //       path: '/cmsmgmt/vechchargers',
  //       icon:    <img src="./../assets/images/menuicons/electricvehicle.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //     },
  //   ],
  // },

  // {
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Setup</Typography>,
  //   icon: <img src="./../assets/images/menuicons/operational.svg" alt="operational"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px'}}  fontFamily="Arial" noWrap={false}>Operating Schedules  <br></br> and Routes </Typography>,
  //       path: '/cmsmgmt/vehicletslot',
  //       icon:    <img src="./../assets/images/menuicons/operationalschedule.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },
  //  ],
  // },

  //  { 
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Settings</Typography>, path: '/cmsmgmt/loadmgmt', icon:   <img src="./../assets/images/menuicons/loadmanagement.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //  },

  //  {
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Planning</Typography>,
  //   icon: <img src="./../assets/images/menuicons/operationalplanning.svg" alt="operational"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography  style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>Design Feasibility </Typography>,
  //       path: '/cmsmgmt/designfsb',
  //       icon:    <img src="./../assets/images/menuicons/simulation.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },

  //     {
  //       name: <Typography  style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>Simulation </Typography>,
  //       path: '/simulation/default',
  //       icon:    <img src="./../assets/images/menuicons/simulation.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },

  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}   noWrap={true}>Reports</Typography>,
  //       path: '/reports/default',
  //       icon:    <img src="./../assets/images/menuicons/reports.svg" alt="reports"  style={{position:'absolute'}} />,
  //     },
  //   ],
  // },


  {
    name: <Typography style={{ marginTop: '8px' }} noWrap={true} >Setting</Typography>,
    icon: <img src="./../assets/images/menuicons/monitoring.svg" alt="monitoring" style={{ position: 'absolute' }} />,
    children: [
      {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px' }} fontFamily="Arial" noWrap={true}>System Setup</Typography>,
        path: '/cmsmgmt/infra',
        icon: <img src="./../assets/images/menuicons/electricvehicle.svg" alt="electricvehicle" style={{ position: 'absolute' }} />,
      }
      ,
      {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px' }} fontFamily="Arial" noWrap={false}>Operational Setup </Typography>,
        path: '/cmsmgmt/vehicletslot',
        icon: <img src="./../assets/images/menuicons/operationalschedule.svg" alt="operationalschedule" style={{ position: 'absolute' }} />,
      },
      {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>User Administration</Typography>,
        path: '/cmsmgmt/usrmgmt',
        icon: <img src="./../assets/images/menuicons/useradminstration.svg" alt="useradminstration" style={{ position: 'absolute' }} />,
      },
      // {
      //   name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}> Access Management</Typography>,
      //   path: '/cmsmgmt/access-management',
      //   icon: <img src="./../assets/images/menuicons/useradminstration.svg" alt="useradminstration" style={{ position: 'absolute' }} />,
      // },
     
    ],
  }
  // ,
  // {
  //   name: <Typography style={{marginTop:'8px'}} noWrap={true} >What if</Typography>,
  //   icon: <img src="./../assets/images/menuicons/monitoring.svg" alt="monitoring"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>To be filled later </Typography>,
  //       path: '/dashboard/default',
  //       icon:    <img src="./../assets/images/menuicons/dashboard.svg" alt="dashboard"  style={{position:'absolute'}} />,
  //     }
  //   ]
  //   }

];


const adminNavigations = [
  {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Dashboard </Typography>,
    path: '/dashboard/default',
    icon: <img src="./../assets/images/menuicons/dashboard.svg" alt="dashboard" style={{ position: 'absolute' }} />,
  },
  // {
  //   name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>depoView </Typography>,
  //   path: '/depoView/default',
  //   icon: <img src="./../assets/images/menuicons/vehicles.svg" alt="vehicles" style={{ position: 'absolute' }} />,
  // }
   {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Vehicles </Typography>,
    path: '/vehicle/default',
    icon: <img src="./../assets/images/menuicons/vehicles.svg" alt="vehicles" style={{ position: 'absolute' }} />,
  }, {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Chargers </Typography>,
    path: '/charger/default',
    icon: <img src="./../assets/images/menuicons/chargers.svg" alt="chargers" style={{ position: 'absolute' }} />,
  }
  // {
  //   name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}  noWrap={true}>Arrival and Departures </Typography>,
  //   path: '/cmsmgmt/arrivals',
  //   icon:    <img src="./../assets/images/menuicons/arrivalsanddepratures.svg" alt="arrivalsanddepratures"  style={{position:'absolute'}} />,
  // },
  //  {
  //   name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}  noWrap={true}>Energy and Power</Typography>,
  //   path: '/cmsmgmt/energy',
  //   icon:    <img src="./../assets/images/menuicons/energyandpower.svg" alt="energyandpower"  style={{position:'absolute'}} />,
  // }
  , {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Events and Alarms </Typography>,
    path: '/cmsmgmt/evets',
    icon: <img src="./../assets/images/menuicons/eventsandalarms.svg" alt="eventsandalarms" style={{ position: 'absolute' }} />,
  }, {
    name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Reports</Typography>,
    path: '/reports/default',
    icon: <img src="./../assets/images/menuicons/reports.svg" alt="reports" style={{ position: 'absolute' }} />,
  },


  // {
  //   name: <Typography noWrap={true} fontSize={14} fontFamily="Arial" style={{marginTop:'8px'}}>System Setup</Typography>,
  //   icon: <img src="./../assets/images/menuicons/systemsetup.svg" alt="systemsetup"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px'}}  fontFamily="Arial" noWrap={true}>Organization and Depot</Typography>,
  //       path: '/cmsmgmt/infra',
  //       icon:    <img src="./../assets/images/menuicons/electricvehicle.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //     },
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px'}}  fontFamily="Arial" noWrap={true}>Vehicles and Chargers</Typography>,
  //       path: '/cmsmgmt/vechchargers',
  //       icon:    <img src="./../assets/images/menuicons/electricvehicle.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //     },
  //   ],
  // },

  // {
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Setup</Typography>,
  //   icon: <img src="./../assets/images/menuicons/operational.svg" alt="operational"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px'}}  fontFamily="Arial" noWrap={false}>Operating Schedules  <br></br> and Routes </Typography>,
  //       path: '/cmsmgmt/vehicletslot',
  //       icon:    <img src="./../assets/images/menuicons/operationalschedule.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },
  //  ],
  // },

  //  { 
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Settings</Typography>, path: '/cmsmgmt/loadmgmt', icon:   <img src="./../assets/images/menuicons/loadmanagement.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //  },

  //  {
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Planning</Typography>,
  //   icon: <img src="./../assets/images/menuicons/operationalplanning.svg" alt="operational"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography  style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>Design Feasibility </Typography>,
  //       path: '/cmsmgmt/designfsb',
  //       icon:    <img src="./../assets/images/menuicons/simulation.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },

  //     {
  //       name: <Typography  style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>Simulation </Typography>,
  //       path: '/simulation/default',
  //       icon:    <img src="./../assets/images/menuicons/simulation.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },

  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}   noWrap={true}>Reports</Typography>,
  //       path: '/reports/default',
  //       icon:    <img src="./../assets/images/menuicons/reports.svg" alt="reports"  style={{position:'absolute'}} />,
  //     },
  //   ],
  // },


  {
    name: <Typography style={{ marginTop: '8px' }} noWrap={true} >Setting</Typography>,
    icon: <img src="./../assets/images/menuicons/monitoring.svg" alt="monitoring" style={{ position: 'absolute' }} />,
    children: [
      {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px' }} fontFamily="Arial" noWrap={false}>Operational Setup </Typography>,
        path: '/cmsmgmt/vehicletslot',
        icon: <img src="./../assets/images/menuicons/operationalschedule.svg" alt="operationalschedule" style={{ position: 'absolute' }} />,
      }
     
    ],
  }
  // ,
  // {
  //   name: <Typography style={{marginTop:'8px'}} noWrap={true} >What if</Typography>,
  //   icon: <img src="./../assets/images/menuicons/monitoring.svg" alt="monitoring"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>To be filled later </Typography>,
  //       path: '/dashboard/default',
  //       icon:    <img src="./../assets/images/menuicons/dashboard.svg" alt="dashboard"  style={{position:'absolute'}} />,
  //     }
  //   ]
  //   }

];
const userNavigations = [

  //  { 
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Settings</Typography>, path: '/cmsmgmt/loadmgmt', icon:   <img src="./../assets/images/menuicons/loadmanagement.svg" alt="electricvehicle"  style={{position:'absolute'}} />,
  //  },

  //  {
  //   name: <Typography noWrap={true} style={{marginTop:'8px'}}>Operational Planning</Typography>,
  //   icon: <img src="./../assets/images/menuicons/operationalplanning.svg" alt="operational"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography  style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>Design Feasibility </Typography>,
  //       path: '/cmsmgmt/designfsb',
  //       icon:    <img src="./../assets/images/menuicons/simulation.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },

  //     {
  //       name: <Typography  style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>Simulation </Typography>,
  //       path: '/simulation/default',
  //       icon:    <img src="./../assets/images/menuicons/simulation.svg" alt="operationalschedule"  style={{position:'absolute'}} />,
  //     },

  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}   noWrap={true}>Reports</Typography>,
  //       path: '/reports/default',
  //       icon:    <img src="./../assets/images/menuicons/reports.svg" alt="reports"  style={{position:'absolute'}} />,
  //     },
  //   ],
  // },


  // {
  //   name: <Typography style={{ marginTop: '8px' }} noWrap={true} >Monitoring</Typography>,
  //   icon: <img src="./../assets/images/menuicons/monitoring.svg" alt="monitoring" style={{ position: 'absolute' }} />,
  //   children: [
      {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Dashboard </Typography>,
        path: '/dashboard/default',
        icon: <img src="./../assets/images/menuicons/dashboard.svg" alt="dashboard" style={{ position: 'absolute' }} />,
      }
      // ,
      // {
      //   name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>depoView </Typography>,
      //   path: '/depoView/default',
      //   icon: <img src="./../assets/images/menuicons/vehicles.svg" alt="vehicles" style={{ position: 'absolute' }} />,
      // }
      , {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Vehicles </Typography>,
        path: '/vehicle/default',
        icon: <img src="./../assets/images/menuicons/vehicles.svg" alt="vehicles" style={{ position: 'absolute' }} />,
      }, {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Chargers </Typography>,
        path: '/charger/default',
        icon: <img src="./../assets/images/menuicons/chargers.svg" alt="chargers" style={{ position: 'absolute' }} />,
      }
      // {
      //   name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}  noWrap={true}>Arrival and Departures </Typography>,
      //   path: '/cmsmgmt/arrivals',
      //   icon:    <img src="./../assets/images/menuicons/arrivalsanddepratures.svg" alt="arrivalsanddepratures"  style={{position:'absolute'}} />,
      // },
      //  {
      //   name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}}  noWrap={true}>Energy and Power</Typography>,
      //   path: '/cmsmgmt/energy',
      //   icon:    <img src="./../assets/images/menuicons/energyandpower.svg" alt="energyandpower"  style={{position:'absolute'}} />,
      // }
      , {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Events and Alarms </Typography>,
        path: '/cmsmgmt/evets',
        icon: <img src="./../assets/images/menuicons/eventsandalarms.svg" alt="eventsandalarms" style={{ position: 'absolute' }} />,
      }, {
        name: <Typography style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} noWrap={true}>Reports</Typography>,
        path: '/reports/default',
        icon: <img src="./../assets/images/menuicons/reports.svg" alt="reports" style={{ position: 'absolute' }} />,
      }
  //   ],
  // }
  // ,
  // {
  //   name: <Typography style={{marginTop:'8px'}} noWrap={true} >What if</Typography>,
  //   icon: <img src="./../assets/images/menuicons/monitoring.svg" alt="monitoring"  style={{position:'absolute'}} />,
  //   children: [
  //     {
  //       name: <Typography style={{textAlign:'left',fontSize:'14px',marginTop:'8px'}} noWrap={true}>To be filled later </Typography>,
  //       path: '/dashboard/default',
  //       icon:    <img src="./../assets/images/menuicons/dashboard.svg" alt="dashboard"  style={{position:'absolute'}} />,
  //     }
  //   ]
  //   }

];

export const navigations = () => {
  debugger;
  const user = JSON.parse(localStorage.getItem("user"));
  if (user === undefined ||user === null||user.role===null||user.role===undefined||user.role.length===0||user.role[0].toString().includes("ROLE_USER")){
    return userNavigations;
   } else if (user.role[0].toString().includes("ROLE_ADMIN")){
    return adminNavigations;
  } else if ( user.role[0].toString().includes("ROLE_ZONAL")){
    return userNavigations;
  } else if (user.role[0].toString().includes("ROLE_SUPERADMIN"))
    return superAdminNavigations;
}