import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Map = Loadable(lazy(() => import('./3DMap')));

const maps = [
  { path: '/map', element: <Map/>, auth: authRoles.admin },
];

export default maps;
