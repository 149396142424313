import * as React from 'react';
import VehicleSlotApi from '../VehicleSlotApi';
import { Button, Grid, styled, Box } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const style = makeStyles({
  titleItemRight: {
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
    marginTop: '10px',
  },
});

const SimpleForm = () => {
  const [selectedFile, setSelectedFile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const api = new VehicleSlotApi();
  const classes = style();
  const [filename, setFilename] = useState('');

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const refreshPage = (event) => {
    window.location.reload(false);
  };

  const handleFileUpload = (e) => {
   
    if (!e.target.files) {
      return;
    }
    setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);
  };

  const handleSubmit = async () => {
    return await uploadForm();
  };

  const uploadForm = async () => {
  
    var base64 = await toBase64(selectedFile);
    base64 = base64.replace('data:', '').replace(/^.+,/, '');

    let blockData = {};
    blockData.blockRecord = base64;
    blockData.uploadType = 'opreating_data';

    setSuccess(false);
    setLoading(true);
    api
      .uploadVehicleBlockData(blockData)
      .then((response) => {
      
        setSuccess(true);
        setLoading(false);
        toast.info('Success ' + response.data.success + ', Failed :' + response.data.failed);
        window.location.href = '/cmsmgmt/vehicletslot';
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: '1rem' }}
              >
                Upload XLSX
                <input
                  type="file"
                  name="myfile"
                  id="myfile"
                  accept=".xlsx"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>

              <Box>{filename}</Box>
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}></Grid>
        </Grid>

        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            color="info"
            alignItems="right"
            className={classes.titleItemRight}
            variant="contained"
            type="button"
            onClick={refreshPage}
            style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
          >
            <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
          </Button>

          <Button
            color="success"
            alignItems="right"
            className={classes.titleItemRight}
            variant="contained"
            sx={buttonSx}
            disabled={loading}
            type="submit"
            style={{ margin: '5px', marginBottom: '-20px' }}
          >
            <Span sx={{ textTransform: 'capitalize' }}>Upload</Span>
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </ValidatorForm>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default SimpleForm;
