import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import DashboardApi from '../DashboardApi';
import { Grid, CircularProgress } from '@mui/material';
import { chartOptions } from 'app/utils/utils';


const EnergyMeterChart = ({ width, color = [], darkTheme }) => {
  const [chart, setData] = useState(null);
  const [loaded, setReady] = useState(false); // flag for loading map
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const api = new DashboardApi();

  useEffect(() => {
    const loadData = () => {
      setLoading(true);
      api
        .getEnergyMeter()
        .then(({ data }) => {
          setData(data);
          setReady(true);
          setLoading(false);
          console.log(data);
        })
        .catch((e) => {
          setError(true);
          setLoading(false); // Set loading to false in case of error
        });
    };

    loadData(); // Load data initially

    const intervalId = setInterval(() => {
      loadData();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [error]);

  const getLoadingOption = () => {
    return { text: 'Data Loading ...' };
  };

  const textColor = darkTheme ? '#fff' : '#000'; // Conditionally set text color
  const titleColor = darkTheme ? '#F2E34C' : '#000';
  const backColor = darkTheme ? '#f9f9f9' : '#000';
  const getOption = () => {
    return {
      series: [
        {
          title: {
            show: false,
            text: 'Energy Meter',
            textStyle: {
              fontSize: 18,
              color: '#333',
            },
          },
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: chart.maxPower,
          // splitNumber: 0,
          itemStyle: {
            color: '#58D9F9',
            shadowColor: 'rgba(0,138,255,0.45)',
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
          progress: {
            show: true,
            roundCap: true,
            width: 18,
          },
          pointer: {
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '75%',
            width: 16,
            offsetCenter: [0, '5%'],
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 18,
              color: [
                [0.6, 'lightgreen'], // Red 
                [0.85, '#F2C386'], // Yellow
                [1, 'red'], // Green
              ],
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            // backgroundColor: backColor,
            borderColor: '#999',
            fontSize: 20,
            borderWidth: 0,
            width: 600,
            lineHeight: 55,
            height: 1,
            borderRadius: 8,
            offsetCenter: [0, '35%'],
            valueAnimation: true,
            formatter: function (value) {
              return '{value|'+value.toFixed(0)+"/"+chart.maxPower+'}{unit|kW}';
            },
            rich: {
              value: {
                fontSize: 25,
                fontWeight: chartOptions().nameTextStyle.fontWeight,
                color: textColor
              },
              unit: {
                fontSize: 18,
                fontWeight: chartOptions().nameTextStyle.fontWeight,
                color: textColor,
                padding: [0, 0, 0, 5],
              },
            },
          },
          data: chart.cpowerArray,
        },
      ],
    };
  };
  
  return (
    <Grid style={{ height: '100%' }}>
      {loading && !loaded ? ( // Render CircularProgress only during initial loading
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress color="primary" /> {/* Circular progress bar */}
        </div>
      ) : (
        <ReactEcharts
          style={{ width: '100%', height: '100%' }}
          notMerge={true}
          option={getOption()}
          loadingOption={getLoadingOption()}
          onChartReady={() => {}} // No need for onChartReady function
          lazyUpdate={true}
        />
      )}
    </Grid>
  );
};

export default EnergyMeterChart;