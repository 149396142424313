import axios from 'axios.js';
export default class OrgApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  addOrg = (data) => {
    return this.init().post('/api/depot/add_org', data);
  };

  getOrgList = (params) => {
    return this.init().get('/api/depot/show_org', { params: params });
  };

  getOrg = (id) => {
    return this.init().get('/api/depot/show_org/'+id,);
  };

  deleteOrg = (id) => {
    return this.init().post('/api/depot/delete_org/' + id);
  };

  updateOrg = (data) => {
    return this.init().post('/api/depot/update_chargerbay', data);
  };
}
