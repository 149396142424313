import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


export default function BasicCard() {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  

  return (
    <Card sx={{width:"22vw" , height:'45vh'}} elevation={3}>
      <CardContent>
        <Typography  >Documentation</Typography>
<Grid container spacing={2}  marginTop={3} marginLeft={'40%'} direction={'column'}>
<Grid item>
<Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
</Grid>
<Grid item>
<Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
</Grid>
<Grid item>
<Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
</Grid>
<Grid item>
<Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
</Grid>
<Grid item>
<Button component="label" size='small' variant="contained" startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
</Grid>
</Grid>

      </CardContent>

    </Card>
  );
}