import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField } from '@mui/material';

import UserApi from '../../usrmgmt/UserApi';
import { FormControlLabel, FormGroup, Switch, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';


const AddUser = ({ setOpen, org }) => {
  const [state, setState] = useState({ orgId: org });
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const api = new UserApi();
  // const [roles, setRoles] = useState([
  //   { id: 5, name: "ROLE_SUPERADMIN" }
  // ])

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);


  useEffect(() => {
    setState({ ...state, orgId: org });

    setState({ ...state, roles: [{ id: 5, name: "ROLE_SUPERADMIN" }] });
  }, []);

  const handleSubmit = (event) => {
    // setRefresh(true);

  

    // console.log(state);

    event.preventDefault();
    api
      .addUser(state)
      .then((res) => {
        toast.success('Successfully added.');
        // setRefresh(false);
        setOpen(false)
      })
      .catch((e) => {
        return toast.error(e.message);
        // console.log('Cannot add  data', e);
      }).finally(()=>window.location.reload())

  };

  const handleChecked = (e) => {
    e.preventDefault();
    if (e.target.checked)
      setState({ ...state, status: "enable" });
    else
      setState({ ...state, status: "disable" });


  }

  const refreshPage = (event) => {
    // setOpen(false);
  };

  const handleChange = (event) => {
    event.persist();


    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    setState({ ...state, [event.target.name]: [event.target.value] });
  };

  const handleSelectCountry = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { name, email, confirmPassword, password, roles, orgId } = state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="name"
              id="standard-basic"
              value={name || ''}
              onChange={handleChange}
              errorMessages={['this field is required']}
              label="Name"
              validators={['required']}
              variant="standard"
              fullWidth
            />

            <TextField
              type="password"
              name="password"
              label="Password"
              value={password || ''}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
              fullWidth
            />

            {/* <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectRole}
                name="roles"
                validators={['required']}
                errorMessages={['this field is required']}
                fullWidth
                onChange={handleSelectChange}
                label="User"
              >
                {
                  roles.map(role => {
                    return <MenuItem value={role} key={role.id}>
                      <em>{role.name}</em>
                    </MenuItem>
                  })
                }

              </Select>
            </FormControl> */}
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="email"
              name="email"
              label="Email/Username"
              onChange={handleChange}
              value={email || ''}
              variant="standard"
              errorMessages={['this field is required']}
              fullWidth
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              onChange={handleChange}
              value={confirmPassword || ''}
              validators={['required', 'isPasswordMatch']}
              errorMessages={['this field is required', "password didn't match"]}
              variant="standard"
              fullWidth
            />
            <FormGroup>
              <FormControlLabel
                onChange={handleChecked}
                control={<Switch />} label="Status" />
            </FormGroup>
            <br />
          </Grid>
        </Grid>

        <Button
          color="info"
          alignItems="right"
          // className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={() => setOpen(false)}
          style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
        </Button>

        <Button
          color="success"
          alignItems="right"
          // className={classes.titleItemRight}
          variant="contained"
          type="submit"
          style={{ margin: '5px', marginBottom: '-20px' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
        </Button>

      </ValidatorForm>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}



const UpdateUser = ({ setOpen, data }) => {
  const [state, setState] = useState(data);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const api = new UserApi();
  // const [roles, setRoles] = useState([
  //   { id: 5, name: "ROLE_SUPERADMIN" }
  // ])

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);


  useEffect(() => {

    setState({ ...state, roles: [{ id: 5, name: "ROLE_SUPERADMIN" }] });
  }, []);

  const handleSubmit = (event) => {
    // setRefresh(true);

   

    // console.log(state);

    event.preventDefault();
    api
      .updateUser(state)
      .then((res) => {
        toast.success('Successfully added.');
        // setRefresh(false);
        setOpen(false)
      })
      .catch((e) => {
        return toast.error(e.message);
        // console.log('Cannot add  data', e);
      }).finally(()=>window.location.reload())

  };

  const handleChecked = (e) => {
    e.preventDefault();
    if (e.target.checked)
      setState({ ...state, status: "enable" });
    else
      setState({ ...state, status: "disable" });


  }

  const refreshPage = (event) => {
    // setOpen(false);
  };

  const handleChange = (event) => {
    event.persist();


    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    setState({ ...state, [event.target.name]: [event.target.value] });
  };

  const handleSelectCountry = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { name, email, confirmPassword, password, roles, orgId } = state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="name"
              id="standard-basic"
              value={name || ''}
              onChange={handleChange}
              errorMessages={['this field is required']}
              label="Name"
              validators={['required']}
              variant="standard"
              fullWidth
            />

            <TextField
              type="password"
              name="password"
              label="Password"
              value={password || ''}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
              fullWidth
            />

            {/* <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectRole}
                name="roles"
                validators={['required']}
                errorMessages={['this field is required']}
                fullWidth
                onChange={handleSelectChange}
                label="User"
              >
                {
                  roles.map(role => {
                    return <MenuItem value={role} key={role.id}>
                      <em>{role.name}</em>
                    </MenuItem>
                  })
                }

              </Select>
            </FormControl> */}
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="email"
              name="email"
              label="Email/Username"
              onChange={handleChange}
              value={email || ''}
              variant="standard"
              errorMessages={['this field is required']}
              fullWidth
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              onChange={handleChange}
              value={confirmPassword || ''}
              validators={['required', 'isPasswordMatch']}
              errorMessages={['this field is required', "password didn't match"]}
              variant="standard"
              fullWidth
            />
            {/* <FormGroup>
              <FormControlLabel
                onChange={handleChecked}
                control={<Switch />} label="Status" />
            </FormGroup> */}
            <br />
          </Grid>
        </Grid>
        <br />

        <Button
          color="info"
          alignItems="right"
          // className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={() => setOpen(false)}
          style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
        </Button>

        <Button
          color="success"
          alignItems="right"
          // className={classes.titleItemRight}
          variant="contained"
          type="submit"
          style={{ margin: '5px', marginBottom: '-20px' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
        </Button>

      </ValidatorForm>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}


export default function BasicCard({ orgId, userId }) {
  const [age, setAge] = React.useState('');
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const api = new UserApi();
  const [refresh,setRefresh]=useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {

    if (userId !== null) {
      api
        .getUserById(userId)
        .then((response) => {
          setUser(response.data);
          // console.log(response.data);
        })
        .catch((e) => {
          // console.log('Cannot load event data', e);
        });
    }

  }, [userId,refresh])

  const handleChecked = (e) => {
    setRefresh(true);
    e.preventDefault();
    let status = "disable";
    if (e.target.checked)
        status = "enable";
    api
        .updateUser({ username: user.username, status: status })
        .then((res) => {
            toast.success('Successfully ' + status + 'd');
            // setOpen(false)
            setRefresh(false);
        })
        .catch((e) => {
            return toast.error(e.message);
            // console.log('Cannot add  data', e);
        });


}




  return (
    <Card sx={{ width: "22vw", height: '45vh' }} elevation={3}>
      <CardContent>
        <Typography  >User</Typography>
        <Grid container>
          {
            user === null ?
              <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setOpen(true)} color="primary" variant="contained" type="button">
                  <Icon>add</Icon>
                  Add User
                </Button>
                <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                  <DialogTitle>Add User</DialogTitle>
                  <DialogContent>
                    <AddUser setOpen={setOpen} org={orgId} />
                  </DialogContent>
                  <DialogActions>
                  </DialogActions>
                </Dialog>

                



              </Grid> :

              <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setOpen(true)} color="primary" variant="contained" type="button">
                  <Icon>update </Icon>
                  Update User
                </Button>
                <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                  <DialogTitle>Update User</DialogTitle>
                  <DialogContent>
                    <UpdateUser setOpen={setOpen} data={user} />
                  </DialogContent>
                  <DialogActions>
                  </DialogActions>
                </Dialog>

                <FormControlLabel
                        style={{margin:0,padding:0}}
                        checked={user.status === "enable"}
                        onChange={handleChecked}
                        control={<Switch  />} />


              </Grid>

          }



        </Grid>
        <br />

        {
          user !== null ?
            <Grid container>
              <Grid item lg={12} md={12}>
                <h3>
                  Name : {user.name}
                </h3>
              </Grid><br />
              <Grid item lg={12} md={12}>
                <h3>
                  Username : {user.username}
                </h3>
              </Grid>
            </Grid> : ""
        }

      </CardContent>

    </Card>
  );
}