import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { chartOptions } from 'app/utils/utils';
// import { chartOptions } from 'app/utils/utils';

const MyEchartsComponent = ({ data = [], darkTheme }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const textColor = darkTheme ? '#F2E34C' : '#000'; // Conditionally set text color
    const titleColor = darkTheme ? '#F2E34C' : '#000';
    const tooltipColor = darkTheme ? '#333333' : '#ffffff';
    const labelColor = darkTheme ? '#000' : '#6a7985';

    const option = data ? {

      
      // title: {
      //   text: 'Load Profile',
      //   padding: [0,0, 0, 20],
      //   textStyle: {
      //     color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
      // },
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: labelColor,
            color: textColor
          }
        },
        backgroundColor: tooltipColor,
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000' // Set text color based on theme
        },
        formatter: function (params) {
          let tooltip = params[0].name + '<br/>';
          params.forEach(param => {
            let formattedValue = Number(param.value).toFixed(2).toLocaleString(); // Limit to 2 decimal places
            tooltip += param.marker + ' ' + param.seriesName + ' : ' + formattedValue + ' A<br/>';
          });
          return tooltip;
        }

      },
      legend: {
        // data: ['Outlet', 'EV',], // Set legend data
        textStyle: {
          color: darkTheme ? '#F2E34C' : '#000'
        }
      },
      toolbox: {
        show: true,
        top: '10px',
        right: 45,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            name: 'Current',
            type: 'png'
          }
        },
        iconStyle: {
          borderColor: darkTheme ? '#F2E34C' : '#000'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '14%',
        containLabel: true
      },
      // dataZoom: [
      //     {
      //       type: 'inside'
      //     },
      //     {
      //       type: 'slider'
      //     }
      //   ],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.filter(obj => obj.location === "Outlet"&&obj.measurand === "Current.Import")
        .map(obj => new Date(obj.value_timestamp)
          .toLocaleTimeString(undefined, { hour12: true, hour: '2-digit', minute: '2-digit' })),
        name: 'Time',
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
        },
        nameLocation: 'middle',
        nameGap: 35,
        nameTextStyle: chartOptions().nameTextStyle,
        nameTextStyle: {
          color: textColor // Set text color based on theme
        }
      },
      yAxis: {
        type: 'value',
        name: 'Current (A)',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: chartOptions().nameTextStyle,
        nameTextStyle: {
          color: textColor // Set text color based on theme
        },
        axisLabel: chartOptions().axisLabel,
        axisLabel: {
          color: textColor // Set text color based on theme
        },
      },

    series: [
      {
          // name: 'Outlet', // Set series name
          type: 'line',
          data: data.filter(obj => obj.location === "Outlet"&&obj.measurand === "Current.Import")
          .map(obj => obj.value),
          smooth: true,
          color: '#ed9f66',
          lineStyle: {
            width: 2, // Set line width if needed
            color: '#ed9f66' // Set line color to red
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#ed9f66'
              },
              // {
              //   offset: 1,
              //   color: 'rgb(1, 191, 236)'
              // }
            ])
          },
          emphasis: {
            focus: 'series'
          },

        },
    
      {
          // name: 'EV', // Set series name
          type: 'line',
          color: '#734444',
          data: data.filter(obj => obj.location === "EV").map(obj => obj.value),
          // areaStyle: { opacity: 0.5 }
          smooth: true,
          lineStyle: {
            width: 4, // Set line width if needed
            color: '#734444' // Set line color to red
          },
          showSymbol: false,
          emphasis: {
            focus: 'series'
          },
      },
      // {
      //     name: 'Inlet', // Set series name
      //     type: 'line',
      //     data: data.filter(obj => obj.location === "Inlet").map(obj => obj.value),
      //     areaStyle: { opacity: 0.5 }
      // }
  ]
} : {};

option && myChart.setOption(option);

return () => {
  myChart.dispose(); // Clean up when the component unmounts
};
}, [data.length]); // Empty dependency array to run this effect only once

return <div ref={chartRef} style={{ width: '100%', height: '50vh',paddingBottom:"15px" }}></div>;
};

export default MyEchartsComponent;
