import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const EvFleet = Loadable(lazy(() => import('./EvFleet')));
const evFleetRoutes = [
  { path: '/ev-fleet/default', element: <EvFleet />, auth: authRoles.admin },
];

export default evFleetRoutes;
