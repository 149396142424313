import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const VehicleAnalytics = Loadable(lazy(() => import('./VehicleListTable')));

const vehicleRoutes = [
  { path: '/vehicle/default', element: <VehicleAnalytics />, auth: authRoles.admin },
];

export default vehicleRoutes;
