import { Box, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Icon, Stack } from '@mui/material';
import './accessmngmnt.css';
import React,{useState} from 'react';
import ExpandableTable from './compoonents/ExpandableTable'
import OrgListTable from '../orgmgmt/OrgListTable';
import SimpleForm from './compoonents/SimpleForm'

function AccessManagementAnalytics() {

  const [open,setOpen]=useState(false);
  const [refresh,setRefresh]=useState(false);
  return (

    <Grid container alignContent={"center"} margin={"auto"} justifyContent={'center'}>
      <Grid item lg={11.5} md={11.5}>



        <br />
        <Box className="breadcrumb">
          {/* <AddUserForm setRefresh={setRefresh}/>  */}
          <Grid container>
            <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={()=>setOpen(true)} color="primary" variant="contained" type="button">
                <Icon>add</Icon>
                Add Organization
              </Button>
              <Dialog fullWidth open={open} onClose={()=>setOpen(false)}>
                <DialogTitle>Add Organization</DialogTitle>
                <DialogContent>
                  <SimpleForm setOpen={setOpen} refresh={refresh} setRefresh={setRefresh}/>
                </DialogContent>
                <DialogActions>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Grid container>
          <Grid item lg={12}>
            <Card>
              <ExpandableTable open={open} refresh={refresh} setRefresh={setRefresh} />
              {/* <OrgListTable/> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccessManagementAnalytics;
