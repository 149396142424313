import {
  Box,
  Icon,
  IconButton,
  styled,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { pink } from '@mui/material/colors';
import OrgApi from './OrgApi';
import { Typography } from '@mui/material';
import EditVehicleForm from '../vehicletslot/VehicleSlot/EditVehicleForm';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  overflowX: 'auto',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

const OrgListTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [APIData, setAPIData] = useState([]);
  const api = new OrgApi();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteOrg = (id) => {
    api
      .deleteOrg(id)
      .then((response) => {
        toast.success('Successfully  deleted.');
        fetchOrgList();
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const fetchOrgList = () => {
    api
      .getOrgList()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const openDepoPage = (id) => {
    window.location.href = '/cmsmgmt/depomgmt/?q=' + id;
  };

  useEffect(() => {
    api
      .getOrgList()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  }, []);

  return (
    <Box width="100%" overflow="auto">
      {/* <Typography style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '-10' }}>
        Organization
      </Typography> */}

      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              Name
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              Country
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              Currency
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              State
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              City
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              ZipCode
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              Address
            </TableCell>
            <TableCell style={{ background: '#333333', color: 'white' }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
            (subscriber, index) => (
              <TableRow key={index}>
                <TableCell align="center" onClick={() => openDepoPage(subscriber.id)}>
                  <a href="#" style={{ color: 'blue' }}>
                    <u>{subscriber.name}</u>
                  </a>
                </TableCell>
                <TableCell align="center">{subscriber.country}</TableCell>
                <TableCell align="center">{subscriber.currency}</TableCell>
                <TableCell align="center">{subscriber.orgState}</TableCell>
                <TableCell align="center">{subscriber.city}</TableCell>
                <TableCell align="center">{subscriber.pinCode}</TableCell>
                <TableCell align="center">{subscriber.address}</TableCell>
                <TableCell align="center">
                  <ButtonGroup>

                    <EditVehicleForm rowData={subscriber} />
                  </ButtonGroup>
                  <IconButton>
                    <DeleteIcon
                      onClick={() => handleDeleteOrg(subscriber.id)}
                      fontSize="small"
                      sx={{ color: pink[500] }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </StyledTable>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Box>
  );
};

export default OrgListTable;
