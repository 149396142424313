import { differenceInSeconds } from 'date-fns';
import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  ButtonGroup,
  Paper,
  darken,
  lighten,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useThemeContext } from 'app/ThemeProvider';

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(now) {

  // const currentDate = new Date(date);
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  // const formattedTime = `${hours}:${minutes}:${seconds}`;

  const formattedTime = now.toLocaleTimeString('en-US', { hour12: false });

  return formattedTime;
  // let difference = differenceInSeconds(new Date(), date);

  // if (difference < 60) return `${Math.floor(difference)} sec`;
  // else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  // else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  // else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  // else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  // else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1)
  );
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export const StyledTable = styled(Table)(({ darkTheme }) => ({
  whiteSpace: 'pre',
  overflowX: 'auto',

  '& thead': {
    '& tr': {
      '& th': {
        paddingLeft: 0
        , paddingRight: 0, fontSize: "16px"
        , fontWeight: "bold", backgroundColor: darkTheme ? '#333333' : '#f9f9f9'
      }
    },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize', fontSize: "16px", } },
  },
}));


// Define the custom styled component
export const StyledDataGrid = styled(DataGrid)(({ theme, darkTheme }) => ({
  '& .MuiDataGrid-root': {
    whiteSpace: 'pre',
    overflowX: 'auto',
    background: darkTheme ? '#333333' : '#f9f9f9', // Set background color based on dark theme
  },
  '& .MuiDataGrid-header': {
    background: darkTheme ? '#333333' : '#f9f9f9', // Set background color based on dark theme
    color: darkTheme ? '#F2E34C' : 'black', // Set text color based on dark theme
    '& .MuiDataGrid-cell': {
      fontWeight: 'bolder',
      background: 'red', // This is a placeholder; you can adjust this based on your requirements
    },
  },
  '& .MuiDataGrid-cell': {
    color: darkTheme ? '#F2E34C' : 'white', // Set text color based on dark theme
    background: darkTheme ? '#333333' : '#f9f9f9', // Set background color based on dark theme
  },
}));

export const chartOptions = () => {
  return {
    axisLabel: {
      color: 'black',
      fontWeight: "bold"
    },
    nameTextStyle: {
      color: 'black',
      fontWeight: "bold"
    }
  }
}


export const componentStyle = { margin: '1px' };
