// import './MapObject.css';

import { Grid, styled, useTheme, Paper } from '@mui/material';

import React, { Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import RouterIcon from '@mui/icons-material/Router';
import DepotSetupApi from '../setdepot/DepotSetupApi';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EvStationIcon from '@mui/icons-material/EvStation';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DashboardApi from '../../../../src/app/views/dashboard/DashboardApi.js'
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import { useThemeContext } from 'app/ThemeProvider';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
// import ConfirmModal from '../../../../src/app/views/depoView/components/ChargingBay.jsx'
const ContentBox = styled('div')(({ theme }) => ({
  marginTop: '20px',
  marginLeft: '30px',
  marginRight: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));
const Root1 = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    // backgroundColor: red[500],
    width: 150,

    textAlign: 'center',
    marginLeft: 20,
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    // backgroundColor: blue[500],
    width: 200,
    // textAlign: "right",

    marginLeft: 20,
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    width: 800,
    marginLeft: 150,
    color: 'black',
    display: 'flex',
  },
}));
const Root2 = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    // backgroundColor: red[500],
    width: 150,

    textAlign: 'center',
    marginLeft: 20,
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    // backgroundColor: blue[500],
    width: 200,
    // textAlign: "right",

    marginLeft: 20,
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    width: 800,
    marginLeft: 20,
    color: 'black',
    display: 'flex',
  },
}));
const Root3 = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    // backgroundColor: red[500],
    // width: 150,
    // textAlign: "center",
    marginLeft: 250,
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
  },
  [theme.breakpoints.up('md')]: {
    // backgroundColor: blue[500],
    // width: 200,
    // textAlign: "right",
    marginLeft: 500,
    fontSize: '25px',
    fontWeight: 'bold ',
    color: 'black',
  },
  [theme.breakpoints.up('lg')]: {
    // backgroundColor: green[500],
    width: 500,
    // textAlign: "left",
    marginLeft: 800,
    marginTop: 40,
    color: 'black',
    fontSize: '27px',
    fontWeight: 'bold ',
  },
}));

const Root4 = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginLeft: 20,
    fontSize: '18px',
    fontWeight: 'bold ',
    color: 'black',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: 20,
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
  },
  [theme.breakpoints.up('lg')]: {
    width: 500,
    marginLeft: 20,
    marginTop: 1,
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold ',
  },
}));

const Root5 = styled('div')(({ theme }) => ({
  // padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginLeft: 5,
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: 5,
    fontSize: '25px',
    fontWeight: 'bold ',
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: 5,
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold ',
    display: 'flex',
  },
}));

const Root6 = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    // backgroundColor: red[500],
    // width: 150,
    // textAlign: "center",
    marginLeft: 50,
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
    // display: "flex"
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    // backgroundColor: 'blue',
    // width: 200,
    // textAlign: "right",
    // marginLeft: 50,
    fontSize: '25px',
    fontWeight: 'bold ',
    color: 'black',
    flexDirection: 'column',
    // display: "flex"
  },
  [theme.breakpoints.up('lg')]: {
    // backgroundColor: 'green',
    // width: 500,
    // textAlign: "left",
    // marginLeft: 100,
    // marginTop: 80,
    // float: 'center',
    color: 'black',
    fontSize: '40px',
    fontWeight: 'bold ',
    display: 'flex',
  },
}));

const Root7 = styled('div')(({ theme }) => ({
  //padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginTop: '-20px',
    marginLeft: '25px',
    width: 300,
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'right',
    marginLeft: '25px',
    marginTop: '-20px',
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    textAlign: 'right',
    marginTop: '-20px',
    fontSize: '20px',
    fontWeight: 'bold ',
    color: 'black',
    display: 'flex',
  },
}));

const FixedBar = () => {
  const { palette } = useTheme();
  const [depos, setDepot] = useState([]);
  const api1 = new DepotSetupApi();
  const [depotId, setDepotId] = React.useState(0);
  const [depotName, setDepotName] = React.useState('');
  const [position, setCoords] = useState([28.5355, 77.391]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { darkTheme } = useThemeContext();
  const [totalVehicle, setTotalVehicle] = React.useState(0);
  const [totalCharger, setTotalCharger] = React.useState(0);
  const [charging, setCharging] = useState(0);
  const [charged, setCharged] = useState(0);
  const [chargedDelay, setChargedDelay] = useState(0);
  const [statusData, setStatusData] = useState(null);
  const [Eata, setEata] = useState(null);

  const [loaded, setReady] = useState(false); // flag for loading map
  const [error, setError] = useState(false);
  const api = new DashboardApi();
  const api2 = new DashboardApi();


  const fetchFleetStatus = () => {
    let isCanceled = false;

    api2
      .getFleetStatus(null)
      .then(({ data }) => {
        if (!isCanceled) {
          setCharging(data.fleet_details.charging);
          setCharged(data.fleet_details.chargedTime);
          setChargedDelay(data.fleet_details.chargeDelay);
        }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  }
  const fetchEnergyMeter = () => {
    let isCanceled = false;

    api
      .getEnergyMeter()
      .then(({ data }) => {
        if (!isCanceled) {
          setEata(data);
          // console.log(data, "33456")
          setReady(true);
        }
      })
      .catch((e) => {
        setError(true);
        console.log('Request history data', e);
      });
  }

  useEffect(() => {

    fetchEnergyMeter();
    fetchFleetStatus();
    fetchChargerStatus();

    const intervalId = setInterval(() => {
      fetchEnergyMeter();
      fetchFleetStatus();
      fetchChargerStatus();


    }, 10000);
    return () => {
      clearInterval(intervalId);
      // source.cancel();
      // isCanceled = true;
    };
  }, [error]);

  // console.log(Eata, 'fgd')


  useEffect(() => {
    // console.log("depos" + depos.length);


    if (localStorage.getItem("selected_depot") !== undefined) {
      setDepotName(localStorage.getItem("selected_depot_name"));
      setDepotId(localStorage.getItem("selected_depot"));
    }
    else

      if (depos.length > 0 && depotId !== 0) {
        localStorage.setItem('selected_depot', depos[0].depotId);
        localStorage.getItem("selected_depot_name", depos[0].depotName);
        setDepotId(depos[0].depotId);

      }
    check_prv_depot();


  }, [depos.length]);


  const fetchChargerStatus = () => {
    let isCanceled = false;

    api2
      .getChargerStatus()
      .then(({ data }) => {
        if (!isCanceled) {
          // console.log(data)
          setStatusData(data);
          // console.log(data);
        }

      })
      .catch((e) => {
        console.log('Request history data', e);
        setStatusData(null);
      });
  }



  const check_prv_depot = () => {
    const prv_depotId = localStorage.getItem('selected_depot');
    if (prv_depotId) {
      setDepotId(prv_depotId);
      countDepotChargerVech(prv_depotId);
    }
  };

  const countDepotChargerVech = (depotId) => {
    api1
      .countDepotChargerVech(null, depotId)
      .then((response) => {
        setTotalVehicle(response.data.v_count);
        setTotalCharger(response.data.c_count);
      })
      .catch((e) => {
        console.log('Cannot load event data', e);
      });
  };


  return (
    <Fragment>

      <Paper elevation={2} style={{ borderRadius: '6px', backgroundColor: darkTheme ? '#333333' : '#eeeeee', margin: "5px" }}>


        <Grid container spacing={1} >


          {/* <Divider orientation="vertical" /> */}
          <Grid item md={5.5} lg={5.5} sm={12} xl={5.5} display={"flex"}
            justifyContent={"center"} justifyItems={"center"}>


            <div style={{ display: "flex", alignItems: "center", fontWeight: "bolder", fontSize: "16px" }}>
            <span style={{ color: darkTheme ? '#fff' : '#000' }}>{totalCharger}</span>
              <EvStationIcon style={{ fontSize: '50px', color: darkTheme ? '#fff' : '#000' }} />
              <span style={{ color: darkTheme ? '#fff' : '#000' }}>{statusData&&statusData.map(status=>status.value).reduce((v1,v2)=>v1+v2)}</span>
            </div>

            

            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: '#4169e1' }} />
                {statusData !== null ? statusData[1].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Available</b>
            </Stack>

            <Stack style={{ margin: "10px" }}>

              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: '#daa520' }} />
                {statusData !== null && statusData[5] != undefined ? statusData[5].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Preparing</b>
            </Stack>

            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: '#008001' }} />
                {statusData !== null ? statusData[0].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Charging</b>
            </Stack>



            <Stack style={{ margin: "10px" }}>

              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: '#daa520' }} />
                {statusData !== null ? statusData[6].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Finishing</b>
            </Stack>

            <Stack style={{ margin: "10px" }}>

              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: 'rgb(75, 192, 192)' }} />
                {statusData !== null ? statusData[3].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Reserved</b>
            </Stack>



            <Stack style={{ margin: "10px" }}>

              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: '#878e98' }} />
                {statusData !== null ? statusData[4].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Unavailable</b>
            </Stack>


            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>
                <FiberManualRecordIcon style={{ color: '#ff5633' }} />
                {statusData !== null ? statusData[2].value : 0}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Fault</b>
            </Stack>

            
            
            


          </Grid>

          <Grid item md={1.5} lg={1.5} sm={12} xl={1.5} display={"flex"}
            justifyContent={"center"} justifyItems={"center"}>


          <div style={{paddingLeft:"20px", display: "flex", alignItems: "center", margin:"auto", fontWeight: "bolder", fontSize: "16px" }}>
            {darkTheme ? (
              <img 
                style={{ 
                  color: darkTheme ? '#fff' : '#000',
                  borderRadius: '50%', // Make the image circular
                  boxShadow: Eata && Eata.cpowerArray[0].value > 0 ? '0 0 30px #008000' : 'none', // Add circular green shadow when value is more than 0
                  animation: Eata && Eata.cpowerArray[0].value > 0 ? 'rotation 20s linear infinite' : 'none' // Apply rotation animation when value is more than 0
                }} 
                width={"25%"}  
                src='/bus_icons/renewable-energy_yello.png' 
                alt='energy'
              />
            ) : (
              <img 
                style={{ 
                  color: darkTheme ? '#fff' : '#000',
                  borderRadius: '50%', // Make the image circular
                  boxShadow: Eata && Eata.cpowerArray[0].value > 0 ? '0 0 30px #008000' : 'none', // Add circular green shadow when value is more than 0
                  animation: Eata && Eata.cpowerArray[0].value > 0 ? 'rotation 20s linear infinite' : 'none' // Apply rotation animation when value is more than 0
                }} 
                width={"25%"}  
                src='/bus_icons/renewable-energy.png' 
                alt='energy'
              />
            )}
            &nbsp;&nbsp;
            <span style={{ color: darkTheme ? '#F2E34C' : '#000'}}>
              {Eata === null ? 0 : (Math.round(Eata.cpowerArray[0].value))} kW
            </span>
          </div>

          <style>
            {`
            @keyframes rotation {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
            `}
          </style>


          </Grid>

          <Grid item md={4} lg={4} sm={12} xl={4} display={"flex"}
            justifyContent={"center"} justifyItems={"center"}>

            <div style={{ display: "flex", alignItems: "center", fontWeight: "bolder", fontSize: "16px" }}>
              <DirectionsBusIcon style={{ marginLeft: '', fontSize: '50px', color: darkTheme ? '#fff' : '#000' }} />
              <span style={{ color: darkTheme ? '#fff' : '#000'}}>{totalVehicle}</span>
            </div>



            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}> {charging}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Charging</b>
            </Stack>

            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}> {charged}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Connected</b>

            </Stack>

            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>{chargedDelay}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Driving</b>
            </Stack>

            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>{charged}</b>
              <b style={{ fontSize: '12px', color: darkTheme ? '#fff' : '#000' }}>Parked</b>

            </Stack>

            <Stack style={{ margin: "10px" }}>
              <b style={{ margin: 'auto', fontSize: '20px', color: darkTheme ? '#fff' : '#000' }}>{chargedDelay}</b>
              <b style={{ fontSize: '12px', textAlign: 'center', color: darkTheme ? '#fff' : '#000' }}>Unavailable</b>

            </Stack>





          </Grid>

          <Grid item md={0.5}>

            <Stack style={{ padding: "10px" }}>
              <RouterIcon style={{ fontSize: 50,color:statusData?"green":"red" }} />

            </Stack>





          </Grid>

          {/* <Grid item md={0.5}>

            <Stack style={{ padding: "10px" }}>
              <RouterIcon style={{ fontSize: 50,color:statusData?"green":"red" }} />

            </Stack>

          </Grid> */}

        </Grid>

      </Paper>

    </Fragment>
  );
};
export default FixedBar;


