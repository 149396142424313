import axios from 'axios.js';
export default class MeterApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };



  getEnergyMeterById = (id) => {
    return this.init().get('/api/device-meter/show_info/'+id,);
  };


  getEnergyMeterByDeviceId = (id) => {
    return this.init().get('/api/device-meter/show_by_deviceId/'+id,);
  };

  getEnergyPowerMonitorByChargerAndMeterId = (params) => {
    return this.init().get('/api/device-meter/eng_power_monitor/'+params.deviceId+'/'+params.meterId);
  };

  getMeterAndChargerLog=(params)=>{
    return this.init().get('/api/report/charger_meter_logs',{params:params});
  }

  getEnergyMeterByDepotId = (id) => {
    return this.init().get('/api/device-meter/show_other/'+id);
  };

}