import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const analytics = [
  { path: '/analytics/default', element: <Analytics/>, auth: authRoles.admin },
];

export default analytics;
