import React,{useState,useEffect} from 'react';
import ReactApexChart from 'react-apexcharts';
import ChargerApi from '../../dashboard/DashboardApi';
import { useThemeContext } from 'app/ThemeProvider';

const BarChart = () => {
  const { darkTheme } = useThemeContext();

  const api = new ChargerApi();

  const [data,setData]=useState(null);

  useEffect(() => {
    const refreshInterval = 10000; // 5 seconds in milliseconds

    const refreshData = () => {
      // Your function to refresh data, e.g., chargerSession()
      chargerBaySession();
    };

    // Initial call
    refreshData();

    // Set up interval for continuous refresh
    const intervalId = setInterval(refreshData, refreshInterval);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means the effect runs once on mount



  const chargerBaySession=()=>{
    api.getChargerBayLoad().
    then(({data})=>{
      setData(data);
    }).catch((e) => {
      // console.log('Request history data', e);
    });
  }
  // const chartData = Array.isArray(data)
  // ? data.map((item) => ({
  //     x: item.transformers,
  //     y: item.load,
  //   }))
  // : [];

  const options = {
    chart: {
      type: 'bar',
      height: 110,
      toolbar: {
        show: false,
      },
      stacked: true,
    //   stackType: '100%'

    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    plotOptions: {
        bar: {
            horizontal: true,
            columnWidth: '70%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'center',
              text: {
                style: {
                  fontSize: '16px',
                  font:"bolder",
                  colors: darkTheme ? '#fff' : 'black',
                },
                formatter: function (val) {
                  return val + '%';
                },
              },
            },
          },
    },
    axisBorder: {
      show: false,
    },
    xaxis: {
        categories: data !== null ? data.transformers : [],
        style: {
          fontSize: '14px',
          color: darkTheme ? '#fff' : 'black',
          fontWeight:"bolder"
        },
        labels: {
          formatter: function (value) {
            return value + '%';
          },
          show: false, // Show x-axis labels
        },
        axisTicks: {
          show: false, // Hide x-axis ticks
        },
        axisBorder: {
          show: false, // Hide x-axis border line
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize:"15px",
            fontWeight: 'bold', // Make Y-axis transformer names bold
            colors: darkTheme ? '#fff' : 'black',
          },
        },
      },
    colors: ['#337ab7', '#dddddd'],

    dataLabels: {
        formatter: function(val, opt) {
          return val+" %";
        }
      }
      ,
    tooltip: {
      enabled: false,
    },

    legend: {
        show: false, // Set to false to hide legends
      },
  };

  const series = [
    { data: data !== null ? data.load : [] },
    // { name: '', data: data !== null ? data.load.map(obj=>100-obj) : [] },
  ];
  

  return (
    <div id="chart" style={{height:"110px"}}>
      {
        data!==null?
        <ReactApexChart options={options} series={series} type="bar" height={110} />
        :""
      }
    </div>
  );
};

BarChart.defaultProps = {
  data: [], // Provide a default value for data (an empty array)
};

export default BarChart;
