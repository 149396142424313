import React, { useState , useEffect} from "react";
import {
  Box,
  TextField,
  IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SimpleForm from './SimpleForm';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import { blue, pink } from '@mui/material/colors';

import Tooltip from '@mui/material/Tooltip';


const EditVehicleForm = ({rowData}) => {

  const [open, setOpen] = React.useState(false);
  // const [APIData1, setAPIData1] = React.useState({
  //   APIData1: rowData,
  // });




  
  const [RouteId, setRouteID] = useState("");
  const [BlockId, setBlockId] = useState("");
  const [Vehicle_Category, setVehicle_Category] = useState("");
  const [Vehicle_Class, setVehicle_Class] = useState("");
  const [Vehicle_Size, setVehicle_Size] = useState("");
  const [BatteryCapacity, setBatteryCapacity] = useState("");
  const [DepartureTime, setDepartureTime] = useState("");
  const [ArrivalTime, setArrivalTime] = useState("");
  
  




  useEffect(() => {
    let finalData;
    let setData;
    try {
       finalData = JSON.stringify(rowData);

       setData  = JSON.parse(finalData);
      // console.log(finalData)

 
    } catch (error) {
      console.error('Invalid JSON:', error);
      // Handle the error appropriately, such as displaying an error message to the user.
      return;
    }
setBlockId(setData.blockId)
    setRouteID(setData.routeId);


    setVehicle_Category(setData.vechileCategory)
    setVehicle_Class(setData.vehicleClass)
    setVehicle_Size(setData.vehicleSize)
     setBatteryCapacity(setData.batteryCapacity)
     setDepartureTime(setData.deptTime)
     setArrivalTime(setData.arrivalTime)
  }, []); 




  const handleClick = () => {
    const newCharger = {
      Id1: RouteId,
      Id2: BlockId,
      Category: Vehicle_Category,
      class: Vehicle_Class,
      Size: Vehicle_Size,
      capacity: BatteryCapacity,
      TimeD: DepartureTime,
      TimeA: ArrivalTime
    };
    };






  const handleClickOpen = () => {
    //setAPIData1(rowData)
    setOpen(true);
  };

  const handleSave = () => {
    handleClick();
     handleClose();
     //alert(JSON.stringify(finalData))
 
     //let finalData1;
     try {
       // finalData1 = JSON.stringify(finalData);
 
       // const fd  = JSON.parse(finalData1);
       // // console.log(fd.id)
     } catch (error) {
       console.error('Invalid JSON:', error);
       // Handle the error appropriately, such as displaying an error message to the user.
       return null;
     }
   
    
  } 

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
   
      <Tooltip title="Edit Operating Schedule"> 
      <EditIcon
       onClick={handleClickOpen}
                      
                      fontSize="small"
                      sx={{ color: blue[500] }}
                    ></EditIcon>
        </Tooltip>

      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        disableEscapeKeyDown="true"
        fullWidth="false"
        maxWidth="sm"
      >
        <DialogTitle>Edit Operating Schedule/Time Table</DialogTitle>
        <DialogContent>
          {/* <SimpleForm /> */}
          {/* <EditTableSlot APIData1={APIData1}/> */}



          <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, },
              }}
              noValidate
              autoComplete="off"
            >
   
      
   


     

              <TextField
              
                id="outlined-basic"
                label="Enter Route ID"
                variant="outlined"
                value={RouteId}
             
                onChange={(event) => setRouteID(event.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Enter Block ID"
                variant="outlined"
               // value="MGL0"
               value={BlockId}
                onChange={(event) => setBlockId(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Enter Vehicle Category"
                variant="outlined"
                value={Vehicle_Category}
                onChange={(event) => setVehicle_Category(event.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Enter Vehicle class"
                variant="outlined"
                value={Vehicle_Class}
                onChange={(event) => setVehicle_Class(event.target.value)}
              />
              

              <TextField
                id="outlined-basic"
                label="Enter Vehicle size"
                variant="outlined"
                value={Vehicle_Size}
                onChange={(event) => setVehicle_Size(event.target.value)}
                />

              <TextField
                id="outlined-basic"
                label="Enter Battery Capacity"
                variant="outlined"
                value={BatteryCapacity}
                onChange={(event) => setBatteryCapacity(event.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Enter Departure Time"
                variant="outlined"
                value={DepartureTime}
                onChange={(event) => setDepartureTime(event.target.value)}
              />

              <TextField
                id="outlined-basic"
                label="Enter Arrival Time"
                variant="outlined"
                value={ArrivalTime}
               onChange={(event) => setArrivalTime(event.target.value)}
                
              />

 
      
      
        </Box>
        </DialogContent>



<DialogActions>
       
        
       <Button
         variant="contained"
         autoFocus
         onClick={handleClose}
         color="error"
         sx={{ border: "1px solid black" }}
         
       >
         Cancel
       </Button>
       <Button
         variant="contained"
         autoFocus
         color="success"
         onClick={handleSave}
         sx={{ border: "1px solid black" }}
       
       >
         Submit
       </Button>
       
     </DialogActions>
      </Dialog>
    </span>
  );
};
export default EditVehicleForm;
