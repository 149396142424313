import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Analytics = Loadable(lazy(() => import('./DepartmentAnalytics')));

const departmentRoute = [
  { path: '/department/default', element: <Analytics />, auth: authRoles.admin },
];

export default departmentRoute;
