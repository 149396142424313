import OrgApi from '../OrgApi';
import { Button, Grid, styled } from '@mui/material';
import { Span } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));
const style = makeStyles({
  titleItemRight: {
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
});

const UpdateOrganization = ({setDialogOpen,data,setRefresh,fetchOrgList,setTab}) => {
  const [state, setState] = useState(data);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const api = new OrgApi();
  const classes = style();

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule('isPasswordMatch');
  }, [state.password]);

  const handleSubmit = (event) => {
    state.date = null;
    // setRefresh(true);
    event.preventDefault();
    setTab(true);

    api
      .updateOrg(state)
      .then((res) => {
        toast.success('Successfully  update.');
        setDialogOpen(false);

      })
      .catch((e) => {
        return toast.error(e.message);
        // console.log('Cannot add  data', e);
      }).finally(()=>{window.location.reload()});
  };

  const handleChange = (event) => {
    event.persist();
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const refreshPage = (event) => {
    window.location.reload(false);
  };

  const handleSelectChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSelectCountry = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { name, currency, country, orgState, pinCode, address, city,allocatedDepots } = state;

  return (
    <div>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="name"
              id="standard-basic"
              value={name || ''}
              onChange={handleChange}
              errorMessages={['this field is required']}
              label="End User"
              validators={['required']}
              variant="standard"
            />

            <TextField
              type="text"
              name="country"
              label="Country"
              value={country || ''}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
            />

            <TextField
              type="text"
              name="pinCode"
              label="Zip Code/Pin Code"
              value={pinCode || ''}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
            />
            <TextField
              type="text"
              name="address"
              label="Site Address"
              value={address || ''}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
            />
             <TextField
              type="number"
              name="allocatedDepots"
              label="Allocate Depots"
              value={allocatedDepots || 0}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              type="text"
              name="currency"
              label="Currency"
              onChange={handleChange}
              value={currency || ''}
              variant="standard"
              errorMessages={['this field is required']}
            />
            <TextField
              type="text"
              name="orgState"
              label="State"
              onChange={handleChange}
              value={orgState || ''}
              errorMessages={['this field is required']}
              validators={['required']}
              variant="standard"
            />

            <TextField
              type="text"
              name="city"
              label="City/ District"
              value={city || ''}
              variant="standard"
              onChange={handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>
        </Grid>

        <Button
          color="info"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="button"
          onClick={()=>setDialogOpen(false)}
          style={{ margin: '5px', marginBottom: '-20px', background: '#e0e0e0', color: 'black' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Cancel</Span>
        </Button>

        <Button
          color="success"
          alignItems="right"
          className={classes.titleItemRight}
          variant="contained"
          type="submit"
          style={{ margin: '5px', marginBottom: '-20px' }}
        >
          <Span sx={{ textTransform: 'capitalize' }}>Submit</Span>
        </Button>
      </ValidatorForm>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default UpdateOrganization;
