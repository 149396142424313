

// import Img from './abc.jpg';
// import './App.css';
// import { useEffect, useState } from 'react';
// import Battery from './Battery'
// import Stack from '@mui/material/Stack';
// import { Paper } from '@mui/material';
// function Sld({chargeBoxId}) {
//   const [zoom, unZoom] = useState(true)
//   const currentDepotId=localStorage.getItem("selected_depot");
//   useEffect(() => {
//     document.getElementById('container').addEventListener('click', function (event) {
//       var container = document.getElementById('container');
//       var zoomedArea = document.getElementById('zoomed-area');

//       var offsetX = event.pageX - container.offsetLeft;
//       var offsetY = event.pageY - container.offsetTop;

//       var centerX = offsetX / container.offsetWidth;
//       var centerY = offsetY / container.offsetHeight;

//       zoomedArea.style.transformOrigin = centerX * 100 + '% ' + centerY * 100 + '%';

//       if (zoom) {
//         unZoom(false)
//         zoomedArea.style.transform = 'scale(4)';
//       } else {
//         zoomedArea.style.transform = 'scale(1)';
//         unZoom(true)
//       }
//     });
//   })


//   return (
//     <div s>
//       <Paper id='container' elevation={2} style={{ overflow: 'scroll', height: '60vh', width: '96vw', borderRadius: '15px', marginLeft: '2%', marginTop: 10 }} >
//         {
//           currentDepotId==="92"?
//           <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${Img})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
//           </div>:""
//         }
//       </Paper>
//     </div>
//   );
// }

// export default Sld;

import Img from './HYD.jpg';
import ImgVIJ from './BZA-WD.jpg';
import ImgSYP1 from './SYP-1.jpg';
import ImgSYP2 from './SYP-2.jpg';
import ImgELU from './ELU.jpg';
import ImgGUN from './GUN.jpg';
import ImgVIZ from './VIZ.jpg';
import './App.css';
import { useEffect, useState } from 'react';
import { useThemeContext } from 'app/ThemeProvider';

import { Paper } from '@mui/material';
function Sld({chargeBoxId}) {
  const [zoom, unZoom] = useState(true)
  const currentDepotId=localStorage.getItem("selected_depot");
  const darkTheme = useThemeContext();
  useEffect(() => {
    document.getElementById('container').addEventListener('click', function (event) {
      var container = document.getElementById('container');
      var zoomedArea = document.getElementById('zoomed-area');

      var offsetX = event.pageX - container.offsetLeft;
      var offsetY = event.pageY - container.offsetTop;

      var centerX = offsetX / container.offsetWidth;
      var centerY = offsetY / container.offsetHeight;

      zoomedArea.style.transformOrigin = centerX * 100 + '% ' + centerY * 100 + '%';

      if (zoom) {
        unZoom(false)
        zoomedArea.style.transform = 'scale(4)';
      } else {
        zoomedArea.style.transform = 'scale(1)';
        unZoom(true)
      }
    });
  })


  return (
    <div sx={{ backgroundColor: darkTheme? '#333333': 'white' }} >
      <Paper id='container' elevation={2} style={{ overflow: 'scroll', height: '60vh', width: '96vw', borderRadius: '15px', marginLeft: '2%', marginTop: 10}}>
        {
          currentDepotId === "92" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${Img})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          ) : currentDepotId === "109" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${ImgVIJ})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          ) : currentDepotId === "106" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${ImgSYP1})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          ) : currentDepotId === "107" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${ImgSYP2})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          ) : currentDepotId === "103" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${ImgELU})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          )  : currentDepotId === "114" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${ImgGUN})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          ) : currentDepotId === "113" ?
          (
            <div id="zoomed-area" style={{ overflow: 'scroll', backgroundImage: `url(${ImgVIZ})`, height: '125vh', width: '96vw', objectFit: "fill", backgroundSize: 'cover', border: 'solid 2px lightgrey', borderRadius: '15px' }} >
            </div>
          ): null
        }
      </Paper>
    </div>
  );
  
}

export default Sld;
