import React, { useState, useRef, useEffect } from 'react';
import { LocationOn } from '@mui/icons-material';
import { IconButton, Typography,Box } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useThemeContext } from 'app/ThemeProvider';
import SimulatorApi from "app/views/simulation/SimulatorApi";
import ChargerApi from 'app/views/charger/ChargerApi';
import img from './View.jpg';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import EvStationIcon from '@mui/icons-material/EvStation';
import Tooltip from '@mui/material/Tooltip';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import BuildIcon from '@mui/icons-material/Build';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GamepadIcon from '@mui/icons-material/Gamepad';
import { pink } from '@mui/material/colors';

const initialPositions = [
  { x: 516, y: 80,y1:195 ,x1:535},
  { x: 469, y: 80,y1:196 ,x1:489},
  { x: 415, y: 80,y1:197 ,x1:437},
  { x: 365, y: 80,y1:198 ,x1:378},
  { x: 310, y: 80,y1:199 ,x1:318},
];
const initialPositions2 = [
  { x: 400, y: 180 , icon: <EvStationIcon sx={{ color: pink[500] }}/>,name:'Charging Station'},
  { x: 760, y: 65,icon: <BuildIcon sx={{ color: pink[500] }}/>,name:'Maintenance'},
  { x: 620, y: 60,icon: <ApartmentIcon sx={{ color: pink[500] }}/>,name:'Proposed Building'},
  {x:655, y: 180,icon: <GamepadIcon sx={{ color: pink[500] }}/>,name:'Proposed Charging Station'},
  { x: 200, y: 210,icon: <ElectricMeterIcon sx={{ color: pink[500] }}/>,name:'Electric Switch Yard'},
  // { x: 250, y: 300,icon: <LocalParkingIcon sx={{ color: pink[500] }}/>,name:'Private Parking'},
];

function Imagee({ Charger }) {
  const [APIData, setAPIData] = useState([]);
  const [iconPositions, setIconPositions] = useState(initialPositions);
  const { darkTheme } = useThemeContext();
  const search = useLocation().search;
  const sim_type = new URLSearchParams(search).get("sim_type");
  const sel_vehicle = new URLSearchParams(search).get("vehicle");
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const api = new SimulatorApi();
  const api1 = new ChargerApi();
  const [iconPositions2, setIconPositions2] = useState(initialPositions);
  const [movedIconIndex, setMovedIconIndex] = useState(null);
  const getLastTwoCharacters = (str) => {
    if (str.length < 2) return str;
    return str.slice(-2);
  };

  const statusColor = (value) => {
    switch (value) {
      case "Available": return "rgb(60 97 208)";
      case "Preparing": return "rgb(211 160 36)";
      case "Charging": return "rgb(0 128 1)";
      case "Finishing": return "rgb(218 165 32)";
      case "Reserved": return "rgb(74 192 192)";
      case "Unavailable": return "rgb(135 142 152)";
      case "Faulted": return "rgb(255 86 51)";
      default: return "white";
    }
  };

  useEffect(() => {
    if (sim_type === "single_v") {
      // showSingleChargerGraph(sel_vehicle);
    } else {
      showChargerStatusGraph();
    }
    const interval = setInterval(() => {
      showChargerStatusGraph();
    }, 1000); // changed interval to 1000ms to reduce load
    return () => clearInterval(interval);
  }, [sim_type]);

  const showChargerStatusGraph = () => {
    api.showChargerStatusGraph()
      .then((response) => {
        setAPIData(response.data[0].sort((o1, o2) => o2.charger_id.localeCompare(o1.charger_id)));
        console.log(response.data[0].sort((o1, o2) => o2.charger_id.localeCompare(o1.charger_id)));
      })
      .catch((e) => {
        console.error("Cannot load event data", e);
      });
  };

  useEffect(() => {
    let isCanceled = false;
    api1.showChargerStMonitorReport()
      .then(({ data }) => {
        if (!isCanceled) {
          console.log(data, 'ghc');
          console.log(data[0].sort((o1, o2) => o2.charger_id.localeCompare(o1.charger_id)));

        }
      })
      .catch((e) => {
        console.error('Request history data', e);
      });
    return () => {
      isCanceled = true;
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      updateIconPositions();
      updateIconPositions2();
    };
    window.addEventListener('resize', handleResize);
    updateIconPositions();
    updateIconPositions2();
    drawCanvas();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const container = containerRef.current;

    if (container && canvas) {
      const width = container.clientWidth;
      const height = container.clientHeight;
      canvas.width = width;
      canvas.height = height;

      const mainImage = new Image();
      mainImage.src = img;
      mainImage.onload = () => {
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(mainImage, 0, 0, width, height);
      };
    }
  };
  const updateIconPositions2 = () => {
    const container = containerRef.current;
    if (!container) return;
  
    const width = container.clientWidth;
    const height = container.clientHeight;
  
    const newPositions = initialPositions2.map(pos => ({
      x: (pos.x / 1000) * width,
      y: (pos.y / 500) * height,
      x1: (pos.x1 / 1000) * width,
      y1: (pos.y1 / 500) * height
    }));
  
    setIconPositions2(newPositions);
  };

  // const updateIconPositions = () => {
  //   const container = containerRef.current;
  //   if (!container) return;

  //   const width = container.clientWidth;
  //   const height = container.clientHeight;

  //   const newPositions = initialPositions.map(pos => ({
  //     x: (pos.x / 1000) * width,
  //     y: (pos.y / 500) * height,
  //     x1: (pos.x1 / 1000) * width,
  //     y1: (pos.y1 / 500) * height
  //   }));

  //   setIconPositions(newPositions);
  // };

  const updateIconPositions = () => {
    const container = containerRef.current;
    if (!container) return;

    const width = container.clientWidth;
    const height = container.clientHeight;

    const newPositions = initialPositions.map(pos => ({
      x: (pos.x / 1000) * width,
      y: (pos.y / 500) * height,
      x1: (pos.x1 / 1000) * width,
      y1: (pos.y1 / 500) * height
    }));

    setIconPositions(newPositions);
  };
  const handleIconClick = (index) => {
    setIconPositions((prevPositions) => {
      const updatedPositions = [...prevPositions];
      if (movedIconIndex === index) {
        // If the same icon is clicked again, reset its position
        updatedPositions[index].y = initialPositions[index].y;
        setMovedIconIndex(null);
        Charger(index);
      } else {
        // Move the new icon to the top
        if (movedIconIndex !== null) {
          Charger(null);
          // Reset the previous moved icon
          updatedPositions[movedIconIndex].y = initialPositions[movedIconIndex].y;
        }
        updatedPositions[index].y -= 20;
        setMovedIconIndex(index);
      }
      return updatedPositions;
    });

  };

  return (
    <div ref={containerRef} style={{ position: 'relative', width: '100%', height: '500px' }}>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      <React.Fragment >
      {iconPositions.map((pos, index) => (
<>
          <IconButton
            onClick={() => handleIconClick(index)}
            style={{
              position: 'absolute',
              left: pos.x,
              top: pos.y,
              padding: 0,
            }}
            
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography style={{
                borderRadius: 35,
                backgroundColor: statusColor(APIData[index]?.status || 'Unavailable'),
                fontSize: 20,
                color: 'white',
                textAlign: 'center',
              }}>
                {getLastTwoCharacters(APIData[index]?.charger_id || '')}
              </Typography>
              <LocationOn
                style={{
                  fontSize: 40,
                  color: statusColor(APIData[index]?.status || 'Unavailable'),
                }}
              />
            </Box>
          </IconButton>
          <Typography style={{
            borderRadius: 35,
            fontSize: 12,
            position: 'absolute',
            left: pos.x1-5,
            top: pos.y1+30 ,
            padding: 0,
            color: 'white',
            transform: `rotateX(45deg)`,
          }}>
            
            {APIData[index]?.current_soc ? `${APIData[index].current_soc}%` : null}
          </Typography>
          </>
      ))}
        
            {iconPositions2.map((pos, index) => (
        <React.Fragment key={index}>
      <Tooltip title={initialPositions2[index].name} style={{              position: 'absolute',
              left: pos.x,
              top: pos.y,
              padding: 0,}}>
        <IconButton>
          {initialPositions2[index].icon}
        </IconButton>
      </Tooltip>
        </React.Fragment>
      ))}
        </React.Fragment>
    </div>
  );
}

export default Imagee;
