import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BillCard from './BillCard'
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, Switch, Tooltip } from '@mui/material';
import UserCard from './UserCard'
import Documentation from './Documentation'
import DeleteIcon from '@mui/icons-material/Delete';

import License from './License'
import OrgApi from '../OrgApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pink } from '@mui/material/colors';
import EditVehicleForm from '../../vehicletslot/VehicleSlot/EditVehicleForm';
import UpdateOrganization from './UpdateOrganization';




function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {

  const api = new OrgApi();

  const [subscriber,setSubscriber] = useState(props.subscriber);
  const { setRefresh,refresh } = props;
  const {setTab}=props;
  const [open, setOpen] = React.useState(false);
  const [isSelected, onSelect] = React.useState(false)
  const [APIData, setAPIData] = useState([]);
  const [dialogOpen,setDialogOpen]=useState(false);

  useEffect(()=>{
    setSubscriber(props.subscriber);
  },[open,refresh])

  const handleDeleteOrg = (id) => {


    if (window.confirm("Please confirm to delete Organization ")) {
      setRefresh(true);

      api
        .deleteOrg(id)
        .then((response) => {
          toast.success('Successfully  deleted.');
          fetchOrgList();
        })
        .catch((e) => {
          // console.log('Cannot load event data', e);
        }).finally(()=>setRefresh(false));
    };

  }
  const fetchOrgList = () => {
    api
      .getOrgList()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      });
  };

  const handleChecked = (e) => {
   

    setSubscriber(()=>{
      const data={ ...subscriber, status: e.target.checked?1:0 };

      // console.log(data);

   api
    .updateOrg(data)
    .then((res) => {
      toast.success('Successfully  update.');
      setDialogOpen(false);

    })
    .catch((e) => {
      return toast.error(e.message);
      // console.log('Cannot add  data', e);
    })

      return data;
    });
    
 


}


  // const openDepoPage = (id) => {
  //   window.location.href = '/cmsmgmt/depomgmt/?q=' + id;
  // };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}

        style={{
          backgroundColor: isSelected ? 'rgba(137, 196, 244, 0.5)' : 'white',
          transition: isSelected ? '0.5s ease background-color' : '0.5s ease background-color'
        }}
        onClick={() => onSelect(!isSelected)}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"

            onClick={() => {
              setOpen(!open)
              //   if(!open){
              // document.getElementById('hello').style.height="60vh"
              // document.getElementById('hello').style.transition="0.5s ease height"
              //   }else{
              //     document.getElementById('hello').style.height="0vh"
              //     document.getElementById('hello').style.transition="0.5s ease height"
              //   }
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
        //  align="center" onClick={() => openDepoPage(subscriber.id)}
        >
          {/* <a href="#" style={{ color: 'blue' }}> */}
          {/* <u> */}
          {subscriber.name}
          {/* </u> */}
          {/* </a> */}
        </TableCell>
        <TableCell >{subscriber.country}</TableCell>
        <TableCell >{subscriber.currency}</TableCell>
        <TableCell >{subscriber.orgState}</TableCell>
        <TableCell >{subscriber.city}</TableCell>
        <TableCell >{subscriber.pinCode}</TableCell>
        <TableCell >{subscriber.address}</TableCell>

        <TableCell > 
          <FormControlLabel
                        // label="Status"
                        style={{margin:0,padding:0}}
                        onChange={handleChecked}
                        checked={subscriber.status!==0}
                        control={<Switch  />} />
        </TableCell>
        <TableCell >
          <Tooltip title="Update">

            {/* <EditVehicleForm rowData={subscriber} /> */}

            <Icon color='primary' onClick={() => setDialogOpen(true)}>update</Icon>
            
           

          </Tooltip>
          <Dialog fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Update Organization</DialogTitle>
              <DialogContent>
                <UpdateOrganization setTab={setTab} fetchOrgList={fetchOrgList} setRefresh={setRefresh} setDialogOpen={setDialogOpen} data={subscriber} />
              </DialogContent>
              <DialogActions>
              </DialogActions>
            </Dialog>
          <Tooltip title="delete">
            <DeleteIcon
              onClick={() => handleDeleteOrg(subscriber.id)}
              fontSize="small"
              sx={{ color: pink[500] }}
            />
          </Tooltip>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, position: 'absolute', backgroundColor: 'rgba(137, 196, 244, 0.08)' }}  >
          <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: 'rgba(137, 196, 244, 0.08)' }}>
            <Box sx={{ margin: 1, backgroundColor: 'rgba(137, 196, 244, 0.08)' }}>
              <Table aria-label="purchases" style={{ backgroundColor: 'rgba(137, 196, 244, 0.08)' }}>
                <TableHead style={{ backgroundColor: 'rgba(137, 196, 244, 0.08)' }}>
                  <TableRow>
                    <TableCell><BillCard /></TableCell>
                    <TableCell><UserCard orgId={subscriber.id} userId={subscriber.userId} /></TableCell>
                    <TableCell ><Documentation /></TableCell>
                    <TableCell ><License /></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow id='hello'
        style={{
          height: open ? '60vh' : '0vh',
          transition: open ? '0.5s ease height' : '0.5s ease height',
          backgroundColor: 'rgba(137, 196, 244, 0.5)'
        }}
      />
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable({ open,refresh,setRefresh }) {
  // const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [APIData, setAPIData] = useState([]);
  const api = new OrgApi();
  const [tab,setTab]=useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };






  useEffect(() => {

    api
      .getOrgList()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log('Cannot load event data', e);
      })
  }, [open, refresh,tab]);


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell >Country</TableCell>
            <TableCell >Currency</TableCell>
            <TableCell >State</TableCell>
            <TableCell >City</TableCell>
            <TableCell >ZipCode</TableCell>
            <TableCell >Address</TableCell>
            <TableCell >Status</TableCell>
            <TableCell >Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {APIData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
            (subscriber, index) => (
              <Row

                key={index} setTab={setTab} subscriber={subscriber} refresh={refresh} setRefresh={setRefresh} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}