// import { applyMiddleware, compose, createStore } from 'redux';

// import RootReducer from './reducers/RootReducer';
// import thunk from 'redux-thunk';

// const initialState = {};
// const middlewares = [thunk];
// let devtools = (x) => x;

// // if (
// //   process &&
// //   process.env.NODE_ENV !== 'production' &&
// //   process.browser &&
// //   window.__REDUX_DEVTOOLS_EXTENSION__
// // ) {
// //   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// // }

// export const Store = createStore(
//   RootReducer,
//   initialState,
//   compose(applyMiddleware(...middlewares), devtools)
// );

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './reducers/RootReducer';

const initialState = {};
const middlewares = [thunk];
let devtools = (x) => x;

if (
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

export const Store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);

export default Store;