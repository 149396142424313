export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const defaultDistanceUnit = 'km';
export const defaultVehicleSize = 'm';
export const defaultBatteryPower  = 'kWh';
export const defaultEfficiency = 'kWh/km';
export const defaultChargingPower = 'kW';
