import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ReportAnalytics = Loadable(lazy(() => import('./ReportAnalytics')));

const reportRoutes = [
  { path: '/reports/default', element: <ReportAnalytics />, auth: authRoles.admin },
];

export default reportRoutes;
