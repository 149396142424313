// import { Provider } from 'react-redux';
// import { useRoutes } from 'react-router-dom';
// import { MatxTheme } from './components';
// import { AuthProvider } from './contexts/JWTAuthContext';
// import { SettingsProvider } from './contexts/SettingsContext';
// import { Store } from './redux/Store';
// import 'react-toastify/dist/ReactToastify.css';

// import routes from './routes';
// import { ToastContainer } from 'react-toastify';

// const App = () => {
//   const content = useRoutes(routes);

//   return (
//     <Provider store={Store}>
//       <SettingsProvider>
//         <MatxTheme>
//           <AuthProvider>{content}</AuthProvider>
//         </MatxTheme>
//       </SettingsProvider>
//       <ToastContainer
//         position="top-right"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//         theme="colored"
//       />
//     </Provider>
//   );
// };

// export default App;


import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from './ThemeProvider'; 
import routes from './routes';
import { ToastContainer } from 'react-toastify';
 
const App = () => {
  const content = useRoutes(routes);
 
  return (
    <ThemeProvider>
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
      </SettingsProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Provider>
    </ThemeProvider>
  );
};
 
export default App;
