import React, { useState, useRef, useCallback } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from 'reactflow';

import 'reactflow/dist/style.css';
import CustomEdge from './CustomEdge';
import Sidebar from './Sidebar';
import Img from './power.png';
import './index.css';
import ImageNode from "./ImageNode";
import { Abc } from '@mui/icons-material';
const nodeTypes = {
  imageNode: ImageNode
};
const initialNodes = [
  {
    id: '1',
    type: "input",
    sourcePosition: 'bottom',
    data: {
      label: (
        <>
          <div style={{direction:'ltr',display:'flex',flexDirection:'column',padding:'0px',margin:'0px',height:'150px'}}>
            <img src={Img} alt="Node Image" style={{ height: '75px', width: '70px',margin:'auto' }} />
            <p style={{padding:0,margin:'auto',fontSize:10 }}>hello</p>
          </div>
        </>
      )
    },
    position: { x: 25, y: 5 },
  },
];
console.log(toString(Img),'syf')

let id = 0;
const getId = () => `dndnode_${id++}`;
const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onConnect = useCallback(
    (params) => {
      const newEdge = {
        ...params,
        id: getId(), // Generate a unique ID for the edge
        type: 'smoothstep',
        animated: true,
        color:'blue',
      };
      setEdges((prevEdges) => [...prevEdges, newEdge]);

    },
    [],
  );
console.log(edges,"edges")
console.log(nodes,'nodes')
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      // debugger;
      event.preventDefault();
console.log(event,'hdbsv')

      const type = event.dataTransfer.getData('application/reactflow');
      const hei = event.dataTransfer.getData('application/height');
      const wdt = event.dataTransfer.getData('application/width');
      const flip = event.dataTransfer.getData('application/flip');
      const ipop = event.dataTransfer.getData('application/input');
      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }
console.log(hei,'fg')
console.log(wdt,'hg')
      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      console.log(type,'111')
      console.log(ipop,'112')
      console.log(flip,typeof(flip),'113')
      const newNode = {
        id: getId(),
        type: ipop==='a'?"output":"default",
        position,
        sourcePosition: "bottom",
        targetPosition: 'top',
        outerWidthWidth:'30px',
        innerWidth:'50px',
        data: {
          label: (
            <>
              <div style={{direction:'ltr',display:'flex',flexDirection:'column',padding:'0px',margin:'0px',height:'150px'}}>
                <img src={type} alt="Node Image" style={{ height: `50%`,width:'50%',margin:'auto'}} />
                <p style={{padding:0,margin:'auto',fontSize:10 }}>hello</p>
               </div>
            </>
          )
        },
        
    };
    
      setNodes((nds) => nds.concat(newNode));
      
    },
    [reactFlowInstance],
  );

  const user = JSON.parse(localStorage.getItem("user"));
  const [isDeleting, setIsDeleting] = useState(user.role[0].includes('SUPERADMIN') ? 'Backspace' : '');
  console.log(isDeleting,'abcdef')
  return (
    <div className="dndflow">
      <ReactFlowProvider >
        <div style={{width:'10vw',display:'inline-block'}}>
        {
          user.role[0].includes('SUPERADMIN') ?
      <Sidebar />:null
      }
      </div>
        <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{marginTop:'10px',height:'60vh',width:'80vw',display:'inline-block'}}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitView
            edgeTypes={{'custom-edge': CustomEdge}}
            draggable='false'
            deleteKeyCode={isDeleting}
            
          >
            {
          user.role[0].includes('SUPERADMIN') ?
      <Controls />:null
      }
          </ReactFlow>
        </div>
        
        
        
      </ReactFlowProvider>
    </div>
  );
};

export default DnDFlow;