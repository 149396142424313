import { Card, Grid, Typography, styled, Paper, Stack, Tooltip, IconButton } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
// import PieChart from './PieChart'
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Image from './Image'
import Image2 from './Image2'
import Image3 from './Image3'
import Image4 from './Image4'
import Topbar from '../../../../components/MatxLayout/Layout1/Layout1Topbar'
// import LoadProfileChart from './shared/LoadProfileChart';
import { useLocation, useNavigate } from 'react-router-dom';
import SimulatorApi from "app/views/simulation/SimulatorApi";
import DashboardApi from 'app/views/dashboard/DashboardApi';
import { useThemeContext } from 'app/ThemeProvider';
import ChargerApi from 'app/views/charger/ChargerApi';
import Speedometer from 'app/views/dashboard/shared/EnergyMeterChart';
import FullscreenIcon from '@mui/icons-material/Fullscreen';



const ContentBox = styled('div')(({ theme }) => ({
  margin: '4px',
  marginTop: '-2px',
  // marginRight: '1px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize',
}));

const Analytics = () => {
  const theme = useTheme();
  const { palette } = useTheme();
  const [totalVehicle, setTotal] = useState(0);
  const [charging, setCharging] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [todayUseskWh, setTodayUse] = useState(0);
  const [peakUseskW, setTPeakUse] = useState(0);
  const [dailyAvg, setDailyAvg] = useState(0);
  const [weeklyChargeCount, setWeeklyChargeCount] = useState(0);
  const [VehicleChargeStatus, setVehicleChargeStatus] = useState("up");
  const [vehicleChargePrcnt, setVehicleChargePrcnt] = useState(0.00);

  const [total_transaction, setTotal_transaction] = useState(0);
  const [total_transactionStatus, setTotal_transactionStatus] = useState("up");
  const [total_transactionStatusPrcnt, setTotal_transactionStatusPrcnt] = useState(0.00);

  const [weeklyChargingTimeInSec, setWeeklyChargingTimeInSec] = useState(0);
  const [avgWeeklyChargingTimeStatus, setAvgWeeklyChargingTimeStatus] = useState("up");
  const [weeklyChargingTimeInPrcnt, setWeeklyChargingTimeInPrcnt] = useState(0.00);

  const [weeklyEnergyUsed, setWeeklyEnergyUsed] = useState(0);
  const [avgWeeklyEnergyTimeStatus, setWeeklyEnergyTimeInSec] = useState("up");
  const [weeklyEnergyTimeInPrcnt, setWeeklyEnergyTimeInPrcnt] = useState(0.00);
  const [newdata, setnewData] = useState([])
  const [weeklyEmission, setWeeklyEmission] = useState(0);
  const currentDepotId = localStorage.getItem("selected_depot");

  const { darkTheme } = useThemeContext();
  const [APIData, setAPIData] = useState([]);
  const [API1Data, set1APIData] = useState([]);
  const api1 = new SimulatorApi();
  const search = useLocation().search;
  const [fullscreen, setfullscreen] = useState(false)

  const sim_type = new URLSearchParams(search).get("sim_type");
  useEffect(() => {
    if (sim_type === "single_v") {
      // showSingleChargerGraph(sel_vehicle);
    } else {
      showChargerStatusGraph();
    }
    const interval = setInterval(() => {
      // if (sim_type === "single_v") {
      // showSingleChargerGraph(sel_vehicle);
      // } else {
      showChargerStatusGraph();
      // }
    }, 100000);
    return () => clearInterval(interval);
  }, []);

  // const showSingleChargerGraph = (vehicleId) => {
  // 	api
  // 		.showSingleChargerStatusGraph(null, vehicleId)
  // 		.then((response) => {
  //       console.log(response.data,"ddp")
  // 			set1APIData(response.data);
  // 		})
  // 		.catch((e) => {
  // 			// console.log("Cannot load event data", e);
  // 		});
  // };
  function getLastTwoCharacters(str) {
    // Ensure the string is at least 2 characters long
    if (str.length < 2) {
      return str;
    }
    // Use slice to get the last two characters
    return str.slice(-2);
  }
  const showChargerStatusGraph = () => {
    api1
      .showChargerStatusGraph()
      .then((response) => {
        setAPIData(response.data[0]);
        console(response.data[0], "aab")
      })
      .catch((e) => {
        // console.log("Cannot load event data", e);
      });
  };
  const [prior, setprior] = useState(null)
  const api12 = new ChargerApi();
  const [dataes, setData] = useState([])
  useEffect(() => {
    let isCanceled = false;
    api12
      .showChargerStMonitorReport()
      .then(({ data }) => {
        if (!isCanceled) {
          setData(data);
          setnewData([])
          console.log(data, 'ghc')
        }
      })
      .catch((e) => {

        console.log('Request history data', e);
      });
    return () => {
      console.log(dataes, "jskb")
    };
  }, [dataes]);
  const api = new DashboardApi();
  const navigate = useNavigate();
  useEffect(() => {
    todayUses();
    fetchWeeklyChargeCount();
    fetchWeeklyChargingTimeInSec();
    fetchWeeklyEnergyUsed();
    fetchWeeklyEmission();

    const intervalId = setInterval(() => {


      todayUses();
      fetchWeeklyChargeCount();
      fetchWeeklyChargingTimeInSec();
      fetchWeeklyEnergyUsed();
      fetchWeeklyEmission();

    }, 1400000);

    return () => {
      clearInterval(intervalId);

    };

  }, []);

  const todayUses = () => {
    let isCanceled = false;
    api
      .getPowerMeter(null)
      .then(({ data }) => {
        if (!isCanceled) {
          setTodayUse(data.today_uses);
          setTPeakUse(data.peak_uses);
          setDailyAvg(data.daily_avg);
        }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };

  const fetchWeeklyEmission = () => {
    let isCanceled = false;
    api
      .getWeeklyEmission(null)
      .then(({ data }) => {
        if (!isCanceled) {
          // console.log(data.weeklyEmission);
          let weeklyEmissionWithoutDecimal = Math.floor(data.weeklyEmission);
          setWeeklyEmission(weeklyEmissionWithoutDecimal);
        }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  }

  const fetchWeeklyChargeCount = () => {
    let isCanceled = false;

    api
      .getWeeklyChargeCount(null)
      .then(({ data }) => {
        if (!isCanceled) {
          setVehicleChargeStatus(data.vehicle_charged_status);
          setVehicleChargePrcnt(parseFloat(data.vehicle_charged_percent).toFixed(2));
          setWeeklyChargeCount(data.total_vehicle_charged);

          setTotal_transaction(data.total_transaction);
          setTotal_transactionStatus(data.transaction_status);
          setTotal_transactionStatusPrcnt(parseFloat(data.transaction_percent).toFixed(2));

        }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };
  const getBorderStyles = (soc) => {
    const borderStyles = {
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderStyle: 'solid',
      borderColor: getColor(soc)
    };

    if (soc >= 25) {
      borderStyles.borderTopWidth = '2px';
    }
    if (soc >= 50) {
      borderStyles.borderRightWidth = '2px';
    }
    if (soc >= 75) {
      borderStyles.borderBottomWidth = '2px';
    }
    if (soc === 100) {
      borderStyles.borderLeftWidth = '2px';
    }

    return borderStyles;
  };
  const fetchWeeklyChargingTimeInSec = () => {
    let isCanceled = false;

    api
      .getWeeklyChargingTimeInSec(null)
      .then(({ data }) => {
        if (!isCanceled) {
          setAvgWeeklyChargingTimeStatus(data.avg_charging_time_status);
          setWeeklyChargingTimeInPrcnt(parseFloat(data.avg_charging_time_percent).toFixed(2));
          setWeeklyChargingTimeInSec(data.avg_charging_time_seconds);
        }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };

  const fetchWeeklyEnergyUsed = () => {
    let isCanceled = false;
    debugger;
    api
      .getWeeklyEnergyUsed(null)
      .then(({ data }) => {
        if (!isCanceled) {
          setWeeklyEnergyUsed(data.avg_energy_used_week);
          setWeeklyEnergyTimeInPrcnt(parseFloat(data.avg_energy_used_last_percent).toFixed(2));
          setWeeklyEnergyTimeInSec(data.avg_energy_used_last_status);
        }
      })
      .catch((e) => {
        console.log('Request history data', e);
      });
  };



  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleSetupDepot = () => {
    navigate('/setdepot/default');
  };
  const VehicleBorder = ({ vehicle }) => (
    <div style={{
      borderRadius: 15,
      width: 45,
      height: 25,
      fontSize: 15,
      margin: 2,
      textAlign: 'center',
      position: 'relative',
      border: '4px',
      ...getBorderStyles(vehicle)
    }}>
      <span style={{ position: 'relative', zIndex: 1, fontWeight: 'bold', color: darkTheme ? 'white' : 'black' }}>{vehicle}%</span>
    </div>
  );
  useEffect(() => {
    let charge = [];
    let available = [];
    let unavailable = [];
    let prep = [];
    let finish = [];
    let resv = [];
    let fault = [];

    dataes.forEach((d) => {
      if (d.status === "Charging") {
        charge.push(d);
      }
      if (d.status === "Available") {
        available.push(d);
      }
      if (d.status === "Unavailable") {
        unavailable.push(d);
      }
      if (d.status === "Preparing") {
        prep.push(d);
      }
      if (d.status === "Finishing") {
        finish.push(d);
      }
      if (d.status === "Reserved") {
        resv.push(d);
      }
      if (d.status === "Faulted") {
        fault.push(d);
      }
    });

    // Combine arrays into a single array in the desired order
    const combinedData = []
      .concat(charge, prep, finish, resv, fault, available, unavailable);

    setnewData(combinedData);
  }, [dataes]);
  newdata.forEach((ele, index) => {
    if (Number(ele.charge_box_id.slice(-1)) === prior) {
      // Remove the element from its current position
      const [item] = newdata.splice(index, 1);
      // Insert the element at the beginning of the array
      newdata.unshift(item);
    }

  });


  const handleFullScreen = () => {
    const element = document.getElementById('hell');
    debugger;
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        setfullscreen(true);
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Safari
        setfullscreen(true);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE11
        setfullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setfullscreen(false);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
        setfullscreen(false);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE11
        setfullscreen(false);
      }
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setfullscreen(false);
      } else {
        setfullscreen(true);
      }
    };

    document.addEventListener('fullscreenchange', onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', onFullscreenChange); // Safari
    document.addEventListener('msfullscreenchange', onFullscreenChange); // IE11

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', onFullscreenChange);
      document.removeEventListener('msfullscreenchange', onFullscreenChange);
    };
  }, []);


  function status(value) {
    console.log(value, "abcde")
    if (value === "Available") {
      return "rgb(60 97 208 / 50%)"
    }
    if (value === "Preparing") {
      return "rgb(211 160 36 /50%)"
    }
    if (value === "Charging") {
      return "rgb(0 128 1 /50%)"
    }
    if (value === "Finishing") {
      return "rgb(218 165 32 /50%)"
    }
    if (value === "Reserved") {
      return "rgb(74 192 192 /50%)"
    }
    if (value === "Unavailable") {
      return "rgb(135 142 152 /50%)"
    }
    if (value === "Faulted") {
      return "rgb(255 86 51)"
    }
  }

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [height, setHeight] = useState('200px');
  var i = 1
  useEffect(() => {
    i++
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setHeight('940px');
      } else {
        setHeight(`${940 * i}px`);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  console.log(prior, "memyself")
  const CustomMobileStepper = styled(MobileStepper)(({ theme }) => ({
    '& .MuiMobileStepper-dot': {
      backgroundColor: darkTheme ? 'lightgrey' : 'grey', // Default dot color
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: darkTheme ? '#F2E34C' : 'blue', // Active dot color
    },
  }));
  const getColor = (soc) => {
    if (soc >= 80) {
      return 'green';
    } else if (soc >= 50) {
      return '#f7b501';
    } else if (soc >= 20) {
      return 'orange';
    } else {
      return 'red';
    }
  };
  const styles = {
    height: height,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  // For Internet Explorer and Edge
    'scrollbar-width': 'none',     // For Firefox
  };
  const setme = (data) => {
    setprior(data)
  }
  const [windowHeight1, setWindowHeight1] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight1(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(newdata, 'aaa')


  const componentHeight = windowHeight1 / 3;

  return (
    <>
      <Grid>
        {/* <Tooltip title="Full Screen" sx={{}}>
              <IconButton   onClick={() => {
    handleFullScreen();   
    setfullscreen(fullscreen ? false : true);  
  }}>
                <FullscreenIcon style={{ color: darkTheme ? '#f2e34c' : '#000' }} />
              </IconButton>
            </Tooltip> */}

      </Grid>
      <Grid id={'hell'} container padding={1} style={{ backgroundColor: !darkTheme ? '#f9f9f9' : 'black', padding: fullscreen ? 5 : 0 }}  >
        {/* {fullscreen?<Topbar/>:<></>} */}
        <div style={{ height: 15 }}></div>
        <Grid item xs={9}  >
          <Grid container spacing={1} id='dad' xs={12} >
            <Grid item xs={12} >
              <Paper elevation={3} width={"50vh"} >

                {
                  // currentDepotId === 92 ?

                  <Grid direction={'column'}>
                    {activeStep === 1 ? <Image Charger={setme} /> : null}
                    {activeStep === 0 ? <Image2 Charger={setme} /> : null}
                    {activeStep === 2 ? <Image3 Charger={setme} /> : null}
                    {activeStep === 3 ? <Image4 Charger={setme} /> : null}
                    <CustomMobileStepper
                      variant="dots"

                      steps={4}
                      position="static"
                      activeStep={activeStep}
                      sx={{ maxWidth: '100%', flexGrow: 1, backgroundColor: darkTheme ? '#333333' : '#fff' }}
                      nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === 3} sx={{ color: darkTheme ? '#F2E34C' : 'blue' }}>
                          Next
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ color: darkTheme ? '#F2E34C' : 'blue' }}>
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          Back
                        </Button>
                      }
                    />
                  </Grid>
                  // : ""
                }
              </Paper>
            </Grid>


            <Grid item lg={4} md={4} sm={4} xs={4} minHeight={fullscreen ? "25vh" : "35vh"} maxHeight={fullscreen ? "30vh" : "60vh"}>
              <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: '100%' }}>
                <Card style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "100%", padding: "15px" }}>
                  <Title style={{ fontWeight: "bold", fontSize: "18px", color: darkTheme ? '#F2E34C' : '#000' }}>Power Meter</Title>

                  <Speedometer darkTheme={darkTheme} />

                </Card>
              </Paper>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4} minHeight={fullscreen ? "25vh" : "35vh"} maxHeight={fullscreen ? "30vh" : "60vh"} >
              <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#9fcba1', height: "100%", padding: "20px" }}>
                <Grid container style={{ height: "100%" }}>
                  <Grid item md={12}>

                    {/* <Grid bgcolor={"white"} padding={"10px"} borderRadius={"10px"}> */}
                    <Title style={{ fontWeight: "bold", fontSize: "18px", color: darkTheme ? '#F2E34C' : '#000' }}>Weekly Emission Savings</Title>
                    {/* </Grid> */}

                  </Grid>

                  <Grid item md={12} container style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/* <Paper elevation={3} style={{ height: '100px', backgroundColor: darkTheme ? '#474747' : '#f9f9f9', display: "flex", alignItems: "center", padding: "0 10px" }}> */}
                    <Stack direction='column' alignItems='center'>
                      <Grid item>
                        {
                          <img src="/co.png" alt="CO icon" style={{ height: '120px', width: '200px' }} />
                        }
                      </Grid>
                      <Grid item>
                        <Typography variant="h3" style={{ fontSize: '30px', margin: "5px", padding: "0", color: darkTheme ? '#F2E34C' : '#000' }}>{weeklyEmission} <a style={{ fontSize: "12px", color: darkTheme ? '#F2E34C' : '#000' }}>Kg</a> </Typography>
                        {/* <Typography variant="body2" style={{ fontSize: '12px', padding: "0", margin: "10px", marginTop: '-2%' }}>Kg</Typography> */}
                      </Grid>
                    </Stack>
                    {/* </Paper> */}
                  </Grid>


                </Grid>
              </Paper>
            </Grid>


            <Grid item lg={4} md={4} sm={4} xs={4} minHeight={fullscreen ? "25vh" : "35vh"} maxHeight={fullscreen ? "30vh" : "60vh"} >
              {/* <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#e2f4ff', height: "100%" }}> */}
              <Card sx={{ px: 3, py: 1, mb: 1 }} style={{ height: "100%", backgroundColor: darkTheme ? '#333333' : '#e2f4ff' }}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Title style={{ fontWeight: "bold", fontSize: "18px", color: darkTheme ? '#F2E34C' : '#000' }}>Depot Statistics Overview</Title>
                  </div>
                </div>

                <hr style={{ borderColor: darkTheme ? '#848482' : '#333333' }} />
                {/* <Paper elevation={3} style={{ height: '28%', margin: 5, backgroundColor: darkTheme ? '#474747' : '#f9f9f9' }}> */}
                <Stack orientation='vertical' spacing={-1} sx={{ marginTop: fullscreen ? '0px' : '7.5px' }}>
                  <div>
                    <Title style={{ marginLeft: 5, fontSize: '15px', color: darkTheme ? '#fff' : '#000' }}>Vehicle Charged</Title>
                  </div>
                  <Grid container direction={'row'} >

                    <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                      <Typography style={{
                        fontSize: fullscreen ? '15px' : '25px',
                        fontWeight: 'bold',
                        paddingTop: '10px',
                        paddingLeft: "10px",
                        width: '100%', height: "100%",
                        color: darkTheme ? '#fff' : '#000'
                      }}>{weeklyChargeCount}  <span
                        style={{
                          padding: "2px 10px 2px 10px", borderRadius: 10,
                          backgroundColor: VehicleChargeStatus === 'up' ? 'green' : '#F98080',
                          color: 'white',
                          fontSize: '12px'
                        }}>{VehicleChargeStatus === 'up' ? '+' : '-'}  {vehicleChargePrcnt} % </span> <span style={{
                          fontSize: '12px'
                        }} > &nbsp; Since Last Week </span></Typography>
                    </Grid>

                    {/* <Grid item xs={12} marginTop={-2}>
                        <Typography style={{ fontSize: '12px', margin: "10px 10px 10px 10px", width: '100%', color: darkTheme ? '#fff' : '#000' }}>
                          <span
                            style={{
                              padding: "2px 10px 2px 10px", borderRadius: 10,
                              backgroundColor: 'green', color: 'white',
                              fontSize: '12px'
                            }}>+1   </span>&nbsp;
                          Since Last Week</Typography>
                      </Grid> */}
                  </Grid>
                  <hr style={{ borderColor: darkTheme ? '#848482' : '#333333', marginTop: fullscreen ? -25 : 5 }} />
                </Stack>

                <Stack orientation='vertical' spacing={-1} sx={{ marginTop: fullscreen ? '0px' : '7.5px' }}>
                  <div>
                    <Title style={{ marginLeft: 5, fontSize: '15px', color: darkTheme ? '#fff' : '#000' }}>Charging Sessions <a style={{ fontSize: '10px' }}>(connector wise)</a></Title>
                  </div>
                  <Grid container direction={'row'} >

                    <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                      <Typography style={{
                        fontSize: fullscreen ? '15px' : '25px',
                        fontWeight: 'bold',
                        paddingTop: '10px',
                        paddingLeft: "10px",
                        width: '100%', height: "100%",
                        color: darkTheme ? '#fff' : '#000'
                      }}>{total_transaction}  <span
                        style={{
                          padding: "2px 10px 2px 10px", borderRadius: 10,
                          backgroundColor: total_transactionStatus === 'up' ? 'green' : '#F98080',
                          color: 'white',
                          fontSize: '12px'
                        }}>{total_transactionStatus === 'up' ? '+' : '-'}  {total_transactionStatusPrcnt} % </span> <span style={{
                          fontSize: '12px'
                        }} > &nbsp; Since Last Week </span></Typography>
                    </Grid>
                    {/* <Grid item xs={12} marginTop={-2}>
                        <Typography style={{ fontSize: '12px', margin: "10px 10px 10px 10px", width: '100%', color: darkTheme ? '#fff' : '#000' }}>
                          <span
                            style={{
                              padding: "2px 10px 2px 10px", borderRadius: 10,
                              backgroundColor: 'green', color: 'white',
                              fontSize: '12px'
                            }}>+1   </span>&nbsp;
                          Since Last Week</Typography>
                      </Grid> */}
                  </Grid>
                  <hr style={{ borderColor: darkTheme ? '#848482' : '#333333', marginTop: fullscreen ? -25 : 5 }} />
                </Stack>

                <Stack orientation='vertical' spacing={-1} sx={{ marginTop: fullscreen ? '0px' : '7.5px' }}>
                  <div>
                    <Title style={{ marginLeft: 5, fontSize: '15px', color: darkTheme ? '#fff' : '#000' }}>Avg. Charging Duration <a style={{ fontSize: '8px' }}>(connector wise)</a></Title>
                  </div>
                  <Grid container direction={'row'} >

                    <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                      <Typography style={{
                        fontSize: fullscreen ? '15px' : '25px',
                        fontWeight: 'bold', paddingLeft: "10px",
                        paddingTop: '10px',
                        width: '100%', height: "100%",
                        color: darkTheme ? '#fff' : '#000'
                      }}>{Math.floor(weeklyChargingTimeInSec / 60)}<a style={{ fontSize: "12px" }}> Mint.</a>
                        &nbsp; <span
                          style={{
                            padding: "2px 10px 2px 10px", borderRadius: 10,
                            backgroundColor: avgWeeklyChargingTimeStatus === 'up' ? 'green' : '#F98080',
                            color: 'white',
                            fontSize: '12px'

                          }}>{avgWeeklyChargingTimeStatus === 'up' ? '+' : '-'}  {weeklyChargingTimeInPrcnt} % </span> <span style={{
                            fontSize: '12px'
                          }} > &nbsp; Since Last Week </span>
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} marginTop={-2}>
                        <Typography style={{ fontSize: '12px', margin: "10px 10px 10px 10px", width: '100%', color: darkTheme ? '#fff' : '#000' }}>
                          <span
                            style={{
                              padding: "2px 10px 2px 10px", borderRadius: 10,
                              backgroundColor: 'green', color: 'white',
                              fontSize: '12px'
                            }}>+1   </span>&nbsp;
                          Since Last Week</Typography>
                      </Grid> */}
                  </Grid>
                  <hr style={{ borderColor: darkTheme ? '#848482' : '#333333', marginTop: fullscreen ? -25 : 5 }} />
                </Stack>
                <Stack orientation='vertical' spacing={-1} sx={{ marginTop: fullscreen ? '0px' : '7.5px' }}>
                  <div>
                    <Title style={{ marginLeft: 5, fontSize: '15px', color: darkTheme ? '#fff' : '#000' }}>Avg. Energy Provided <a style={{ fontSize: '8px' }}>(connector wise)</a></Title>
                  </div>
                  <Grid container direction={'row'} >

                    <Grid item xs={12} direction={'column'} style={{ height: "7vh" }} >
                      <Typography style={{
                        fontSize: fullscreen ? '15px' : '25px',
                        fontWeight: 'bold', paddingLeft: "10px",
                        paddingTop: '10px',
                        width: '100%', height: "100%",
                        color: darkTheme ? '#fff' : '#000'
                      }}>{Math.round(parseFloat(weeklyEnergyUsed))}<span style={{ fontSize: "12px" }}> kWh</span>
                        &nbsp; <span
                          style={{
                            padding: "2px 10px 2px 10px", borderRadius: 10,
                            backgroundColor: avgWeeklyEnergyTimeStatus === 'up' ? 'green' : '#F98080',
                            color: 'white',
                            fontSize: '12px'
                          }}>{avgWeeklyEnergyTimeStatus === 'up' ? '+' : '-'}  {weeklyEnergyTimeInPrcnt} % </span> <span style={{
                            fontSize: '12px'
                          }} > &nbsp; Since Last Week </span> </Typography>
                    </Grid>
                    {/* <Grid item xs={12} marginTop={-2}>
                        <Typography style={{ fontSize: '12px', margin: "10px 10px 10px 10px", width: '100%', color: darkTheme ? '#fff' : '#000' }}>
                          <span
                            style={{
                              padding: "2px 10px 2px 10px", borderRadius: 10,
                              backgroundColor: 'green', color: 'white',
                              fontSize: '12px'
                            }}>+1   </span>&nbsp;
                          Since Last Week</Typography>
                      </Grid> */}
                  </Grid>

                </Stack>

              </Card>

            </Grid>
          </Grid>
        </Grid>
        {/* height={'50vh'} */}
        <Grid item xs={3}>
          <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#fff', padding: 10, paddingBottom: "10px" }}>
            <Grid item xs={12}  >

              <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#000000' : '#fff', padding: 7, marginBottom: 7, marginTop: -10 }}>
                <Title style={{
                  fontWeight: "bold", fontSize: "18px",
                  color: darkTheme ? '#F2E34C' : '#000', textAlign: 'center', margin: 3, backgroundColor: darkTheme ? '#000000' : '#fff'
                }}> Chargers</Title>
              </Paper>
            </Grid>
            {/* style={{maxHeight:"895px"}} */}
            <Grid item xs={12} direction={'column'} alignSelf={'top'} spacing={1} height={fullscreen ? '47rem' : "55rem"} overflow={"auto"} style={{ scrollbarWidth: 'none' }}>

              {newdata.map((data, index) => (
                <Paper elevation={3} style={{ marginTop: index === 0 ? 0 : 5, backgroundColor: status(data.status) }}>

                  {/* <Grid padding={3}>

                  <Title style={{ fontWeight: "bold", fontSize: "15px", color: darkTheme ? '#F2E34C' : '#000', textAlign: 'center' }}> Charger ID : {data.charge_box_id}</Title>
                  {data.status === "Charging" ? <>
                    <Typography sx={{ color: darkTheme ? '#fff' : '#000' }}>Vehicle License Plate :<a>{data.vehicle_license_plate}</a></Typography>
                    <Typography sx={{ color: darkTheme ? '#fff' : '#000' }}>SoC (%): <a>{data.Percent}</a></Typography>
                    <Grid container >
                      <Grid item xs={6}>
                        <Typography sx={{ color: darkTheme ? '#fff' : '#000' }}>Power (kW): <a>{data.power.toFixed(2)}</a></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ color: darkTheme ? '#fff' : '#000' }}>Voltage (V) : <a>{data.V}</a></Typography>
                      </Grid>
                    </Grid>
                    <Grid container >
                      <Grid item xs={6}>
                        <Typography sx={{ color: darkTheme ? '#fff' : '#000' }}>Current (A) :<a>{data.A}</a></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ color: darkTheme ? '#fff' : '#000' }}>Status: <a>{data.status}</a></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ color: darkTheme ? '#fff' : '#000', fontSize: 12 }}>Connector: <a>{data.connector_id}</a></Typography>
                      </Grid>
                    </Grid>
                  </> :
                    <>
                      <Typography sx={{ color: darkTheme ? '#fff' : '#000', fontSize: 12 }}>Connector: <a>{data.connector_id}</a></Typography>
                      <Typography sx={{ color: darkTheme ? '#fff' : '#000', fontSize: 12 }}>Status: <a>{data.status}</a></Typography>

                    </>
                  }
                </Grid> */}
                  <>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column', textAlign: 'center', borderRadius: '10px', width: '99%', margin: 3
                    }}>
                      <Paper elevation={3} style={{ padding: 10, backgroundColor: !darkTheme ? '#f9f9f9' : '#333333' }}>
                        {data.status === "Charging" ? <>
                          <Grid container xs={12} style={{
                            display: 'flex',
                            flexDirection: 'row', margin: 3, height: 40, width: '100%', borderRadius: 50, background: darkTheme ? 'black' : 'white', border: !darkTheme ? '2px solid lightgray' : '2px solid darkgrey', paddingLeft: 5, textAlign: 'left'
                          }}>

                            <Grid item xs={10} style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }} >
                              <div style={{ border: !darkTheme ? '2px solid darkgrey' : '2px solid white', borderRadius: 15, color: darkTheme ? 'white' : 'black', fontSize: 12, padding: 4, paddingTop: 4, fontWeight: 'bold', margin: 3, marginLeft: 0, marginBottom: darkTheme ? 0 : 9, height: 30 }}> {data.power ? Number(data.power).toFixed(2) : 0}kW</div>
                              <div style={{ backgroundImage: darkTheme ? `linear-gradient(to right, black,${getColor(data.Percent)}` : `linear-gradient(to right, white,${getColor(data.Percent)}  )`, marginTop: 7.5, height: 20, width: (data.Percent ? data.Percent * 1.5 : 0), paddingLeft: 5, paddingBottom: 5, fontWeight: 'bold', fontSize: 11, color: darkTheme ? 'white' : 'black' }}></div>
                              {darkTheme ? (

                                <img
                                  style={{
                                    color: darkTheme ? '#fff' : '#000',
                                    borderRadius: '50%', // Make the image circular
                                    animation: 'rotation 20s linear infinite',
                                    height: 30,
                                    width: 30,
                                    marginTop: 3,
                                    marginLeft: -5,
                                    backgroundColor: 'black',
                                  }}
                                  width={"25%"}
                                  src='/bus_icons/renewable-energy_yello.png'
                                  alt='energy'
                                />
                              ) : (
                                <div>
                                  <img
                                    style={{
                                      color: darkTheme ? '#fff' : '#000',
                                      borderRadius: '50%', // Make the image circular
                                      animation: 'rotation 20s linear infinite',
                                      height: 30,
                                      width: 30,
                                      marginTop: 3,
                                      marginLeft: -5,
                                      backgroundColor: 'white',
                                      zIndex: -1
                                    }}
                                    width={"25%"}
                                    src='/bus_icons/renewable-energy.png'
                                    alt='energy'
                                  />

                                </div>
                              )}


                            </Grid>
                            {data.status === "Charging" ? <>
                              <Grid item xs={2} style={{ marginTop: 3.8 }}><VehicleBorder vehicle={data.Percent} /></Grid>
                            </> : null}
                          </Grid>
                        </> : null}
                        <Grid container xs={12} style={{ backgroundColor: status(data.status), borderRadius: 10, margin: 3, marginTop: 5 }}>
                          <Grid style={{
                            width: '120px',
                            // font-size: 12px;
                            // font-weight: 600px;
                            textAlign: 'left',
                            backgroundColor: darkTheme ? '#000000' : '#c1c1c1',
                            // color: #ffffff;
                            padding: 7,
                            borderRadius: 10,
                            fontWeight: 'bold',
                            clipPath: 'polygon(0% 100%, 100% 100%, calc(100% - 30px) 0%, 0% 0%, calc(100% - 30px) 0%, 0% 0%)', color: darkTheme ? '#f3e34d' : 'black'
                          }}>
                            Charger ID :
                          </Grid>
                          <Grid style={{ padding: 7, fontWeight: 'bold', color: darkTheme ? '#ffffff' : 'black' }}>{data.charge_box_id}&nbsp;&nbsp;&nbsp;<span style={{ padding: 3, border: darkTheme ? '1px solid white' : '1px solid black', borderRadius: 7, color: darkTheme ? '#ffffff' : 'black' }}>C{data.connector_id}</span></Grid>

                        </Grid>
                        {data.status === "Charging" ? <>
                          <Grid style={{
                            display: 'flex',
                            flexDirection: 'row', textAlign: 'left', borderRadius: 10, fontSize: 15, marginTop: 5, marginLeft: 5, color: darkTheme ? 'white' : 'black'
                          }}>
                            <div style={{ fontWeight: 'bold', color: darkTheme ? '#f3e34d' : 'black' }}>Vehicle License Plate :&nbsp;&nbsp;</div>{data.vehicle_license_plate}
                          </Grid>
                          <Grid style={{
                            display: 'flex',
                            flexDirection: 'row', textAlign: 'left', borderRadius: 10, fontSize: 15, marginTop: 5, marginLeft: 5, color: darkTheme ? 'white' : 'black'
                          }}>
                            <div style={{ fontWeight: 'bold', color: darkTheme ? '#f3e34d' : 'black' }}>Voltage (V):&nbsp;&nbsp;</div> {data.V}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style={{ fontWeight: 'bold', color: darkTheme ? '#f3e34d' : 'black' }}>Current (A):&nbsp;&nbsp;</div>{data.A}
                          </Grid>
                        </> : null}
                        {/* ))}  */}

                        <div>

                          <Grid style={{
                            display: 'flex',
                            flexDirection: 'row', textAlign: 'left', borderRadius: 10, fontSize: 15, marginTop: 5, marginLeft: 5, color: darkTheme ? 'white' : 'black'
                          }}>
                            <div style={{ fontWeight: 'bold', color: darkTheme ? '#f3e34d' : 'black' }}> Status :&nbsp;&nbsp;</div>{data.status}
                          </Grid>
                          {/* ))}  */}
                        </div>

                      </Paper>
                    </div>
                  </>
                </Paper>
              ))}

            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>

  );
};
export default Analytics;