import React, { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';

const EnergyMeterPowerChart = ({value=0}) => {
  const chartRef = useRef(null);

  const option = {
    series: [
      {
        type: 'gauge',
        center: ['50%', '60%'],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: 60,
        itemStyle: {
          color: '#1e90ff'
        },
        progress: {
          show: true,
          width: 30
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            width: 30
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        anchor: {
          show: false
        },
        title: {
          show: false
        },
     
        detail: {
          width: '60%',
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [0, '-15%'],
          fontSize: 16,
          fontWeight: 'bolder',
          formatter: '{value} ',
          color: 'inherit'
        },
     
        data: [
          {
            value: parseFloat(value).toFixed(2)
          }
        ]
      },
 
    ]
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const random = +(Math.random() * 60).toFixed(2);
  //     if (chartRef.current) {
  //       chartRef.current.getEchartsInstance().setOption({
  //         series: [
  //           {
  //             data: [
  //               {
  //                 value: random
  //               }
  //             ]
  //           },
  //           {
  //             data: [
  //               {
  //                 value: random
  //               }
  //             ]
  //           }
  //         ]
  //       });
  //     }
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <ReactECharts
      ref={chartRef}
      option={option}
    style={{ height: '15rem', width: '100%' }}
    />
  );
};

export default EnergyMeterPowerChart;
