import axios from 'axios.js';
export default class VehicleSlotApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  addVehicleSlot = (data) => {
    return this.init().post('/api/vehicle/add_vehicle_time_slot', data);
  };

  getVehicleList = (params) => {
    return this.init().get('/api/vehicle/show', { params: params });
  };

  getVehicleListByDepotId = (depotId,params) => {
    return this.init().get('/api/vehicle/show/'+depotId, { params: params });
  };


  getVehicleSlot = (params) => {
    return this.init().get('/api/vehicle/show_vehicle_time_slot', { params: params });
  };

  deleteBlockData = (id) => {
    return this.init().get('/api/vehicle/delete_block_data/' + id);
  };

  deleteOrg = (id) => {
    return this.init().post('/api/depot/delete_org/' + id);
  };

  updateVehicleSlot = (data) => {
    return this.init().post('/api/charger/update_chargerbay', data);
  };

  uploadVehicleBlockData = (data) => {
    return this.init().post('/api/vehicle/upload_block_data', data);
  };

  getVehicleArrivalSchedule = (params) => {
    return this.init().get('/api/vehicle/arrival_status/', { params: params });
  };

  getVehicleDepartureSchedule = (params) => {
    return this.init().get('/api/vehicle/departure_status/', { params: params });
  };

  getRouteDataList = (params) => {
  
    return this.init().get('/api/vehicle/route_list/', { params: params });
  };

  getRouteDataListByDepot = (params,depotId) => {
  
    return this.init().get('/api/vehicle/route_list_depot/'+depotId, { params: params });
  };


  
  deleteVehicleBlockData = (data) => {
    return this.init().post('/api/vehicle/delete_block_data', data);
  };


  addBulkVehicles = (data) => {
    return this.init().post('/api/vehicle/upload_data', data);
  };

  addVOPData = (data) => {
    return this.init().post('/api/depot/upload_block_head', data);
  };


  addBlockSchedule = (data) => {
    return this.init().post('/api/depot/upload_block_data', data);
  };

  getVehicleAssignments = (depotId,params) => {
    return this.init().get('/api/vehicle/show_vehicle_assignment/'+depotId, { params: params });
  };
  
}
