// import './App.css';
// import { Grid, Stack, Paper, Typography, Card, Button, CircularProgress } from '@mui/material'
// import MeterPowerMonitor from './MeterPowerMonitor'
// import ChargingPortChart from './ChargingPowerGraph'
// import EnergyChart from './EnergyChart'
// import VoltageChart from './Voltagechart'
// import Currentchart from './CurrentChart'
// import { useEffect, useState } from 'react';
// import MeterApi from './MeterApi';
// import { useThemeContext } from 'app/ThemeProvider';
// import EnergyMeterPowerChart from './meter-charts/EnergyMeterPowerChart';

// function MeterDetail({ row = {}, open }) {

//   const api = new MeterApi();
//   const [value, setvalue] = useState(0);
//   const [chargerMonitorMap, setChargerMonitorMap] = useState({});
//   const [totalConsumtion, setTotalConsumtion] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const { darkTheme } = useThemeContext();
//   const [connectorPk, setConnectorPk] = useState(0);

//   const [transactionLogData, setTransactionLogData] = useState([]);
//   const [meter, setMeterData] = useState({});

//   const [frequency,setFrequency]=useState(0);

//   useEffect(() => {

//     if (row.meter_pk !== 0) {
//       debugger;

//       getMeterDetail(row.meter_pk);
//     }
//   }, [row.meter_pk]);


//   const getMeterDetail = (id) => {
//     setLoading(true)

//     api.getEnergyMeterById(id)
//       .then(({ data }) => {
//         console.log(data);
//         setMeterData(data);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log('Request history data', e);
//       });

//   }


//   // useEffect(() => {

//   //   const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
//   //   setChargerMonitorMap(data);

//   //   const timeout = setInterval(() => {
//   //     const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
//   //     setChargerMonitorMap(data);
//   //   }, 5000);

//   //   return () => {
//   //     clearInterval(timeout);
//   //   };


//   // }, [])

//   const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
//   const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
//   const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
//   const textColorUnselected = darkTheme ? 'white' : 'black';

//   return (
//     <div className="App" style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>
//       <Grid container spacing={0.5} style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>

//         <Grid item lg={2} md={2} sm={2} xs={2}  >
//           <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height:"34.7rem", }}>
//             <Card style={{ backgroundColor: darkTheme ? '#333333' : '#b2c2ee', height: "100%", padding: "10px" }} >
//               <Stack >
//                 <Typography sx={{ fontWeight: 'bolder'
//                   , fontSize: 18, textAlign: 'left'
//                   , color: darkTheme ? '#F2E34C' : '#000' }}>Meter Details</Typography>
//               </Stack>

//               <Stack
//                 justifyContent="center"
//                 alignItems="center"
//                 sx={{ width: '100%' }}
//               >
//                 <img src="/meter_logo.jpg" width="70%" />
//               </Stack>

//               <Stack direction={'column'}>
//                 <Grid container direction={'row'}>
//                   <Grid item xs={5.5} spacing={3} marginBottom={2}>
//                     <br />
//                     <Stack direction={'column'} margin={'auto'}>
//                       <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
//                         {/* {row && row.chargeBoxId} */}

//                         {meter.make ?? "unknown"}
//                       </Typography>
//                       <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
//                         Make
//                       </Typography>
//                     </Stack>
//                   </Grid>
//                   <Grid item xs={1}>

//                   </Grid>

//                 </Grid>
//               </Stack>

//               <Stack direction={'column'}>
//                 <Grid container direction={'row'}>
//                   <Grid item xs={5.5} spacing={3} marginBottom={2}>
//                     <Stack direction={'column'} margin={'auto'}>
//                       <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
//                         {meter.deviceId ?? "unknown"}

//                       </Typography>
//                       <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
//                         Linked Device
//                       </Typography>
//                     </Stack>
//                   </Grid>
//                   <Grid item xs={1}>

//                   </Grid>

//                 </Grid>
//               </Stack>


//               <Stack direction={'column'}>
//                 <Grid container direction={'row'}>
//                   <Grid item xs={5.5} spacing={3} marginBottom={2}>
//                     <Stack direction={'column'} margin={'auto'}>
//                       <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
//                         {meter.meterId ?? "unknown"}
//                       </Typography>
//                       <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
//                         Meter ID
//                       </Typography>
//                     </Stack>
//                   </Grid>
//                   <Grid item xs={1}>

//                   </Grid>

//                 </Grid>
//               </Stack>

//               <Stack direction={'column'}>
//                 <Grid container direction={'row'}>
//                   <Grid item xs={5.5} spacing={3} marginBottom={2}>
//                     <Stack direction={'column'} margin={'auto'}>
//                       <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
//                         {meter.model ?? "unknown"}
//                       </Typography>
//                       <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
//                         Model
//                       </Typography>
//                     </Stack>
//                   </Grid>
//                   <Grid item xs={1}>

//                   </Grid>

//                 </Grid>
//               </Stack>
//               <Stack direction={'column'}>
//                 <Grid container direction={'row'}>
//                   <Grid item xs={5.5} spacing={3} marginBottom={2}>
//                     <Stack direction={'column'} margin={'auto'}>
//                       <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
//                         {meter.serialNumber ?? "unknown"}
//                       </Typography>
//                       <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
//                         Serial Number
//                       </Typography>
//                     </Stack>
//                   </Grid>
//                 </Grid>
//               </Stack>
//               <Stack direction={'column'}>
//                 <Grid container direction={'row'}>
//                   <Grid item xs={5.5} spacing={3} marginBottom={2}>
//                     <Stack direction={'column'} margin={'auto'}>
//                       <Typography sx={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
//                         Offline
//                       </Typography>
//                       <Typography sx={{ fontWeight: 'regular', fontSize: 12, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
//                         Status
//                       </Typography>
//                     </Stack>
//                   </Grid>
//                 </Grid>
//               </Stack>
//             </Card>
//           </Paper>
//         </Grid>

//         <Grid container   lg={2} md={2} sm={2} xs={2} height={"35rem"}>
//           <Grid item md={12} margin={'3px'}>
//             <Paper>
//                 <Typography sx={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Power Factor</Typography>
//               <EnergyMeterPowerChart />
//             </Paper>
//           </Grid>


//           <Grid item md={12} margin={'3px'}>
//             <Paper>
//                 <Typography sx={{ fontWeight: 'bolder', fontSize: 18, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Frequency (Hz)</Typography>
//               <EnergyMeterPowerChart value={frequency} />
//             </Paper>
//           </Grid>
//         </Grid>


//         <Grid item lg={8} md={8} sm={8} xs={8}>
//           <Paper elevation={3} >
//             <Typography variant='h6' sx={{ fontWeight: 'bolder', textAlign: 'left',padding:"10px" }} style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
//               Power Monitor</Typography>

//             <MeterPowerMonitor setFrequency={setFrequency} row={meter} setTotalConsumtion={setTotalConsumtion} darkTheme={darkTheme} />

//           </Paper>
//         </Grid>

//       </Grid>





//       <Grid style={{ marginTop: '5px' }}  >



//         <Paper style={{ minHeight: '50vh', maxHeight: '100vh', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', display: 'none' }} elevation={2}>

//           <Stack spacing={2} direction="row" padding={'15px'}>
//             <Button variant="contained"
//               disabled={value === 0}
//               style={{
//                 backgroundColor: value === 0 ? backgroundColorSelected : backgroundColorUnselected,
//                 color: value === 0 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "5px 0 0 5px"
//               }}
//               onClick={() => { setvalue(0) }}>Power</Button>

//             <Button variant="contained"
//               disabled={value === 1}
//               style={{
//                 backgroundColor: value === 1 ? backgroundColorSelected : backgroundColorUnselected,
//                 color: value === 1 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
//               }}

//               onClick={() => { setvalue(1) }}>Consumption</Button>
//             <Button variant="contained"

//               disabled={value === 2}
//               style={{
//                 backgroundColor: value === 2 ? backgroundColorSelected : backgroundColorUnselected,
//                 color: value === 2 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
//               }}
//               onClick={() => { setvalue(2) }}>Frequency</Button>



//             <Button variant="contained"
//               disabled={value === 4}
//               style={{
//                 backgroundColor: value === 4 ? backgroundColorSelected : backgroundColorUnselected,
//                 color: value === 4 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0", boxShadow: 0
//               }}
//               onClick={() => { setvalue(4) }}>Voltage</Button>

//             <Button variant="contained"
//               disabled={value === 5}
//               style={{
//                 backgroundColor: value === 5 ? backgroundColorSelected : backgroundColorUnselected,
//                 color: value === 5 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0 5px  5px 0"
//               }}
//               onClick={() => { setvalue(5) }}>Amperage</Button>

//           </Stack>



//           {value === 0 ? (loading ? <CircularProgress /> : <ChargingPortChart darkTheme={darkTheme}
//             data={transactionLogData.filter(obj => obj => obj.unit === "W"
//               && obj.connector_pk === connectorPk)} />) : null}

//           {value === 1 ? (loading ? <CircularProgress /> : <EnergyChart darkTheme={darkTheme}
//             data={transactionLogData.filter(obj => obj.measurand === "Energy.Active.Import.Register"
//               && obj.connector_pk === connectorPk
//             )}
//           />) : null}



//           {value === 4 ? (loading ? <CircularProgress /> : <VoltageChart darkTheme={darkTheme}
//             data={transactionLogData.filter(obj => obj.measurand === "Voltage"
//               && obj.connector_pk === connectorPk
//             )}
//           />) : null}

//           {value === 5 ? (loading ? <CircularProgress /> : <Currentchart darkTheme={darkTheme}
//             data={transactionLogData.filter(obj => obj.unit === "A"
//               && obj.connector_pk === connectorPk
//             )}
//           />) : null}
//         </Paper>

//       </Grid>
//     </div>
//   );
// }

// export default MeterDetail;



import './App.css';
import { Grid, Stack, Paper, Typography, Card, Button, CircularProgress } from '@mui/material'
import MeterPowerMonitor from './MeterPowerMonitor'
import ChargingPortChart from './ChargingPowerGraph'
import EnergyChart from './EnergyChart'
import VoltageChart from './Voltagechart'
import Currentchart from './CurrentChart'
import { useEffect, useState } from 'react';
import MeterApi from './MeterApi';
import { useThemeContext } from 'app/ThemeProvider';
import EnergyMeterPowerChart from './meter-charts/EnergyMeterPowerChart';

function MeterDetail({ row = {}, open }) {

  const api = new MeterApi();
  const [value, setvalue] = useState(0);
  const [chargerMonitorMap, setChargerMonitorMap] = useState({});
  const [totalConsumtion, setTotalConsumtion] = useState(0);
  const [loading, setLoading] = useState(false);
  const { darkTheme } = useThemeContext();
  const [connectorPk, setConnectorPk] = useState(0);

  const [transactionLogData, setTransactionLogData] = useState([]);
  const [meter, setMeterData] = useState({});

  const [frequency, setFrequency] = useState(0);
  const [powerFactor, setPowerFactor] = useState(0);


  useEffect(() => {

    if (row.meter_pk !== 0) {
      debugger;

      getMeterDetail(row.meter_pk);
    }
  }, [row.meter_pk]);


  const getMeterDetail = (id) => {
    setLoading(true)

    api.getEnergyMeterById(id)
      .then(({ data }) => {
        console.log(data);
        setMeterData(data);
        setLoading(false);
      })
      .catch((e) => {
        console.log('Request history data', e);
      });

  }


  // useEffect(() => {

  //   const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
  //   setChargerMonitorMap(data);

  //   const timeout = setInterval(() => {
  //     const data = JSON.parse(localStorage.getItem("chargerMonitor")) || {};
  //     setChargerMonitorMap(data);
  //   }, 5000);

  //   return () => {
  //     clearInterval(timeout);
  //   };


  // }, [])

  const backgroundColorSelected = darkTheme ? '#F2E34C' : 'black'; // Background color of selected tab
  const backgroundColorUnselected = darkTheme ? '#334155' : '#d9d9d9';
  const textColorSelected = darkTheme ? 'black' : 'white'; // Text color of selected tab
  const textColorUnselected = darkTheme ? 'white' : 'black';

  return (
    <div className="App" style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>
      <Grid container spacing={0.5} style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>

        <Grid item lg={2} md={2} sm={2} xs={2}  >
          <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9', height: "35.8rem", }}>
            <Card style={{ backgroundColor: darkTheme ? '#333333' : '#b2c2ee', height: "100%", padding: "10px" }} >
              <Stack >
                <Typography sx={{
                  fontWeight: 'bolder'
                  , fontSize: 18, textAlign: 'left'
                  , color: darkTheme ? '#F2E34C' : '#000'
                }}>Meter Details</Typography>
              </Stack>

              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <img src="/meter_logo.jpg" width="70%" />

                {/* <img src="/image-removebg-preview.jpg" width="70%" style={{ filter: darkTheme ? 'invert(1)' : 'invert(0)' }} /> */}
              </Stack>

              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={1}>

                    <Stack direction={'column'} margin={'auto'}>

                      <Typography sx={{ fontWeight: 'bold', fontSize: 14, fontWeight: 'bold', textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        Make :
                      </Typography>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        {/* {row && row.chargeBoxId} */}

                        {meter.make ?? "unknown"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>

                </Grid>
              </Stack>

              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={1}>
                    <Stack direction={'column'} margin={'auto'}>

                      <Typography sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        Linked Device
                      </Typography>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        {meter.deviceId ?? "unknown"}

                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>

                </Grid>
              </Stack>


              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={1}>
                    <Stack direction={'column'} margin={'auto'}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        Meter ID
                      </Typography>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        {meter.meterId ?? "unknown"}
                      </Typography>

                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>

                </Grid>
              </Stack>

              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={1}>
                    <Stack direction={'column'} margin={'auto'}>

                      <Typography sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        Model
                      </Typography>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        {meter.model ?? "unknown"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>

                  </Grid>

                </Grid>
              </Stack>
              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={1}>
                    <Stack direction={'column'} margin={'auto'}>

                      <Typography sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        Serial Number
                      </Typography>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        {meter.serialNumber ?? "unknown"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
              <Stack direction={'column'}>
                <Grid container direction={'row'}>
                  <Grid item xs={5.5} spacing={3} marginBottom={1}>
                    <Stack direction={'column'} margin={'auto'}>

                      <Typography sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>
                        Status
                      </Typography>
                      <Typography sx={{ fontSize: 13, textAlign: 'left', color: darkTheme ? '#fff' : '#000' }}>
                        {console.log(parseInt(frequency))}
                        {parseInt(frequency??0)===0?"Offline":"Online"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Card>
          </Paper>
        </Grid>

        <Grid container lg={2} md={2} sm={2} xs={2} spacing={0.2} marginLeft={0.4} height={"35rem"}>
          <Grid item md={12} margin={'3px'} >
            <Paper style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} >
              <Typography sx={{ fontWeight: 'bolder', padding: 1, fontSize: 18, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Power Factor</Typography>
              <EnergyMeterPowerChart value={powerFactor} />
            </Paper>
          </Grid>


          <Grid item md={12} margin={'3px'}>
            <Paper style={{ backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
              <Typography sx={{ fontWeight: 'bolder', padding: 1, fontSize: 18, textAlign: 'left', color: darkTheme ? '#F2E34C' : '#000' }}>Frequency (Hz)</Typography>
              <EnergyMeterPowerChart value={frequency} />
            </Paper>
          </Grid>
        </Grid>


        <Grid item lg={7.9} md={7.9} sm={8} xs={8}>
          <Paper elevation={3} style={{ backgroundColor: darkTheme ? '#333333' : '#ffffff' }}>
            <Typography variant='h6' sx={{ fontWeight: 'bolder', padding: 1, textAlign: 'left', }} style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
              Power Monitor</Typography>
            <Grid padding={2} >


              <MeterPowerMonitor setFrequency={setFrequency} 
              setPowerFactor={setPowerFactor}
              row={meter} setTotalConsumtion={setTotalConsumtion} darkTheme={darkTheme} />
            </Grid>
          </Paper>
        </Grid>

      </Grid>


      {/* <Grid lg={12} md={12} sm={12} xs={12} style={{ marginTop: '5px' }}>
        <Paper style={{ minHeight: '50vh', maxHeight: '60vh', backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} elevation={2}>
          <TransChart row={row}
            setSelectedTransactionPk={setSelectedTransactionPk}
            connectorPk={connectorPk} setConnectorPk={setConnectorPk} darkTheme={darkTheme} />
        </Paper>

      </Grid> */}


      <Grid style={{ marginTop: '5px' }}  >



        <Paper style={{ minHeight: '50vh', maxHeight: '100vh', backgroundColor: darkTheme ? '#333333' : '#f9f9f9', display: 'none' }} elevation={2}>

          <Stack spacing={2} direction="row" padding={'15px'}>
            <Button variant="contained"
              disabled={value === 0}
              style={{
                backgroundColor: value === 0 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 0 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "5px 0 0 5px"
              }}
              onClick={() => { setvalue(0) }}>Power</Button>

            <Button variant="contained"
              disabled={value === 1}
              style={{
                backgroundColor: value === 1 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 1 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              }}

              onClick={() => { setvalue(1) }}>Consumption</Button>
            <Button variant="contained"

              disabled={value === 2}
              style={{
                backgroundColor: value === 2 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 2 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              }}
              onClick={() => { setvalue(2) }}>Frequency</Button>

            {/* <Button variant="contained"
              disabled={value === 3}
              style={{
                backgroundColor: value === 3 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 3 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0"
              }}
              onClick={() => { setvalue(3) }}>Temperature</Button> */}

            <Button variant="contained"
              disabled={value === 4}
              style={{
                backgroundColor: value === 4 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 4 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0", boxShadow: 0
              }}
              onClick={() => { setvalue(4) }}>Voltage</Button>

            <Button variant="contained"
              disabled={value === 5}
              style={{
                backgroundColor: value === 5 ? backgroundColorSelected : backgroundColorUnselected,
                color: value === 5 ? textColorSelected : textColorUnselected, margin: 0, borderRadius: "0 5px  5px 0"
              }}
              onClick={() => { setvalue(5) }}>Amperage</Button>

          </Stack>

          {/* <Grid textAlign='left' padding={'15px'}> <Typography style={{ color: darkTheme ? '#F2E34C' : '#000' }} variant='h6'>Date - {transactionLogData.length
            && (new Date(transactionLogData[0].value_timestamp).toDateString()
              + "     , Duration : "
              + ((
                new Date(transactionLogData[transactionLogData.length - 1].value_timestamp).getTime()

                - new Date(transactionLogData[0].value_timestamp).getTime()) / 60000).toFixed(2) + " Mins , "
              + "Transaction Id : " + selectedTransactionPk
            )}   </Typography></Grid> */}

          {/* <Grid textAlign='left' padding={'15px'}>
            <div style={{ padding: '2px', display: 'inline-block', margin: '5px', maxHeight: '50px', backgroundColor: backgroundColorUnselected, borderRadius: '10px' }}>
              <Typography style={{ color: darkTheme ? '#F2E34C' : '#000' }}>
                <span style={{ borderRadius: '10px', padding: '2px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                  Date: {transactionLogData.length && (
                    <span style={{ padding: '5px', display: 'inline-block', margin: '5px' }}>
                      {new Date(transactionLogData[0].value_timestamp).toDateString()}
                    </span>
                  )}
                </span>
                <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                  Duration: {transactionLogData.length && (
                    <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>
                      {((new Date(transactionLogData[transactionLogData.length - 1].value_timestamp).getTime()
                        - new Date(transactionLogData[0].value_timestamp).getTime()) / 60000).toFixed(2)} Mins
                    </span>
                  )}
                </span>
                <span style={{ padding: '2px', borderRadius: '10px', display: 'inline-block', margin: '5px', fontSize: '16px' }}>
                  Transaction ID: <span style={{ padding: '2px', display: 'inline-block', margin: '5px' }}>{selectedTransactionPk}</span>
                </span>
              </Typography>
            </div>
          </Grid> */}


          {value === 0 ? (loading ? <CircularProgress /> : <ChargingPortChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj => obj.unit === "W"
              && obj.connector_pk === connectorPk)} />) : null}

          {value === 1 ? (loading ? <CircularProgress /> : <EnergyChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "Energy.Active.Import.Register"
              && obj.connector_pk === connectorPk
            )}
          />) : null}

          {/* {value === 2 ? (loading ? <CircularProgress /> : <SocChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "SoC"
              && obj.connector_pk === connectorPk
            )}
          />) : null} */}

          {/* {value === 3 ? (loading ? <CircularProgress /> : <TempChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "SoC"
              && obj.connector_pk === connectorPk
            )}
          />) : null} */}

          {value === 4 ? (loading ? <CircularProgress /> : <VoltageChart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.measurand === "Voltage"
              && obj.connector_pk === connectorPk
            )}
          />) : null}

          {value === 5 ? (loading ? <CircularProgress /> : <Currentchart darkTheme={darkTheme}
            data={transactionLogData.filter(obj => obj.unit === "A"
              && obj.connector_pk === connectorPk
            )}
          />) : null}
        </Paper>

      </Grid>
    </div>
  );
}

export default MeterDetail;