import axios from 'axios.js';
export default class SimulatorApi {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  init = () => {
    this.client = axios.create({
      timeout: 120000,
    });

    return this.client;
  };

  addVehicleSlot = (data) => {
    return this.init().post('/api/vehicle/add_vehicle_time_slot', data);
  };

  getVehicleList = (params) => {
    return this.init().get('/api/vehicle/show', { params: params });
  };

  getVehicleSlot = (params) => {
    return this.init().get('/api/vehicle/show_vehicle_time_slot', { params: params });
  };

  deleteBlockData = (id) => {
    return this.init().get('/api/vehicle/delete_block_data/' + id);
  };

  deleteOrg = (id) => {
    return this.init().post('/api/depot/delete_org/' + id);
  };

  updateVehicleSlot = (data) => {
    return this.init().post('/api/charger/update_chargerbay', data);
  };

  uploadVehicleBlockData = (data) => {
    return this.init().post('/api/vehicle/upload_block_data', data);
  };

  getVehicleArrivalSchedule = (params) => {
    return this.init().get('/api/sim_v01/show_arrivals/', { params: params });
  };

 getChargingProcess = (params) => {
    return this.init().get('/api/sim_v01/show_charge_process/', { params: params });
  };

  getVehicleDepartureSchedule = (params) => {
    return this.init().get('/api/sim_v01/show_departure/', { params: params });
  };

  getVehicleOnRoad = (params) => {
    
    return this.init().get('/api/sim_v01/show_vehicle_motion/', { params: params });
  };


  getRouteDataList = (params) => {
   
    return this.init().get('/api/vehicle/route_list/', { params: params });
  };

  deleteVehicleBlockData = (data) => {
    return this.init().post('/api/vehicle/delete_block_data', data);
  };


  showChargerStatusGraph = (params) => {
    return this.init().get('/api/dash/show_charge_process_graph/', { params: params });
  };

}