import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Tableo from './Table';
import a from './CHARGER.png'
import { useThemeContext } from 'app/ThemeProvider';

export default function AnchorTemporaryDrawer(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { darkTheme } = useThemeContext();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const statusColor = (element) => {
    if (element.status.includes("Charging"))
      return '#008001';
    if (element.status.includes("Preparing"))
      return 'rgb(218, 165, 32)';
    if (element.status.includes("Finishing"))
      return 'rgb(218, 165, 32)';
    else if (element.status.includes("Reserved"))
      return 'rgb(75, 192, 192)';
    else if (element.status.includes("Unavailable"))
      return '#878e98';
    else if (element.status.includes("Fault"))
      return '#ff5633';
    return '#4169e1';
  }



  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}
            style={{
              border: 'solid #109cf1 2px ', backgroundColor: 'lightgrey', margin: '2px', fontSize: 12,
              color: 'black', fontWeight: 'bolder', display: 'inline-flex', flexDirection: 'column'
            }} fullWidth>

            <div style={{ display: 'inline-flex', flexDirection: 'row' }}><img style={{ height: 20, }} src={a} />
              {props.element.charger_id}</div> <div style={{ height: '3px', backgroundColor: statusColor(props.element), width: '100%', margin: 3 }}></div></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{
              sx: { width: '50%', backgroundColor: darkTheme ? '#333333' : '#fff' }, // Customize the width here
            }}
          >
            <Tableo chargeBoxId={props.element.charger_id} />



          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
