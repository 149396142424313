import Loadable from 'app/components/Loadable';
import { authRoles } from '../../auth/authRoles';
import { lazy } from 'react';
import ChargingBay from './components/ChargingBay';

const Analytics = Loadable(lazy(() => import('./Analytics')));


const depoViewRoutes = [
  { path: '/depoView/default', element:<ChargingBay/>  , auth: authRoles.admin },  
];

export default depoViewRoutes;
